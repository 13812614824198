const dateSort = (item1, item2) => {
    if (item1.oprSchedDate > item2.oprSchedDate) {
        return 1;
    }
    if (item1.oprSchedDate < item2.oprSchedDate) {
        return -1;
    }
    return 0;
};

export default dateSort;
