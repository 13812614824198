import React, { createContext, useReducer } from 'react';
import { globalState, globalReducer } from './globalReducer';
import { useActions } from './globalActions';
import { applyGlobalMiddelware } from './middlewares';

const GlobalCtx = createContext(globalState);

const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalReducer, globalState);

    const superDispatch = applyGlobalMiddelware(dispatch);
    const actions = useActions(state, superDispatch);

    return (
        <GlobalCtx.Provider value={{ state, superDispatch, actions }}>
            {children}
        </GlobalCtx.Provider>
    );
};

export { GlobalCtx, GlobalProvider };
