import React from 'react';
import useLocale from '../components/Helpers/locale';

const getTimeInHours = (processTime = 0, setupTime = 0) => {
    return ((processTime + setupTime) / 60).toFixed(2);
};

const WorkItem = props => {
    const item = props.item;
    const { t } = useLocale();
    const code = item.routeAttrib?.projId ?
        ('PR: ' + item.routeAttrib?.projId) :
        ('PO: ' + item.prodOrderId);

    return (
        <li
            key={item.id}
            className={
                'container d-flex justify-content-between align-middle p-0 py-1 pl-1' +
                (props.itemsSelected.indexOf(item) !== -1 && props.isEditable ? ' active' : '')
            }
            onClick={e => props.isEditable && props.updateSelected(e, item)}>
            <div className='column col'>
                <p className='row font-weight-bolder'>{code}</p>
                <p className='row'>
                    {item.oprId} {item.routeDescription}
                </p>
                <p className='row'>
                    {t('workcenterInfo.worktimeTotal')}:{' '}
                    {getTimeInHours(item.myReportedProcessTime, item.myReportedSetupTime)}
                </p>
            </div>
            <div className='column col-5 px-1'>
                <div className='row flex justify-content-between pb-1'>
                    <input
                        type='number'
                        step='0.01'
                        min='0'
                        max='6'
                        readOnly
                        className='form-control-plaintext border-white text-white text-center pr-1'
                        onClick={e => {
                            e.stopPropagation();
                        }}
                        value={getTimeInHours(item.additionalTime)}
                    />
                </div>
                <div className='row flex justify-content-between pt-1'>
                    <div className='col align-self-center pr-2'>{t('finished')}:</div>
                    <button
                        type='button'
                        className={
                            'col btn btn-secondary btn-nopointer align-self-center text-white' +
                            (item.additionalFinished === undefined && item.canStart
                                ? ' bg-warning'
                                : ' bg-success')
                        }
                        value='false'>
                        <b>
                            {(item.additionalFinished === undefined && item.canStart
                                ? t('no')
                                : t('yes')
                            ).toUpperCase()}
                        </b>
                    </button>
                </div>
            </div>
        </li>
    );
};

export default WorkItem;
