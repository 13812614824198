import React, { useState } from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import NewWindow from 'react-new-window';

const ListItem = ({ props }) => {
    const item = props.item;
    const idx = props.key;
    const currentWrkCtr = props.currentWrkCtr;
    const [showFolderView, setShowFolderView] = useState(false);
    const [folderPath, setFolderViewPath] = useState();

    const folderButtonClick = (e, viewItem = -1) => {
        e.preventDefault();
        e.stopPropagation();
        setFolderViewPath(setFolderUri(viewItem));
        setShowFolderView(true);
    };

    const FolderView = props => {
        return (
            <NewWindow
                url={props.uri}
                onBlock={() => alert('Allow popups!')}
                onUnload={() => {
                    setShowFolderView(false);
                    setFolderViewPath('');
                }}
            />
        );
    };
    function showScrap() {
        return currentWrkCtr.flags?.find(flag => flag === 'FEShowScrapQty') !== undefined;
    }
    function setFolderUri(folderIndex) {
        if (!item.folderPathObj.show) return '';

        if (folderIndex === -1) return item.folderPathObj.uri;
        if (item.folderPathObj.coProductUris[folderIndex] === undefined)
            throw new Error('Cannot find set folderPath for coProduct "' + folderIndex + '"');

        return item.folderPathObj.coProductUris[folderIndex];
    }

    const CoProducts = props => {
        const item = props.item;
        const [isCollapsed, setCollapsed] = useState(true);
        const collapsibleBody = 'accordionCollapse_' + props.idx;
        if (!item.hasCoProducts) return <></>;

        const onAccordionClick = e => {
            e.stopPropagation();
            setCollapsed(!isCollapsed);
        };

        const CoProductsBody = () => {
            return (
                <>
                    {item.coProducts.map((line, idx) => (
                        <div className='pb-2' key={idx}>
                            <div className='d-flex flex-row justify-content-between item text-warning'>
                                <div className='d-inline-flex'>
                                    <div>{line.coProductLineId + ' ' + line.itemId}</div>
                                    <div>
                                        {line.goodQty} / {line.qty} {line.unit}
                                    </div>
                                    {showScrap() && (
                                        <div>
                                            {line.scrapQty} {line.unit}
                                        </div>
                                    )}
                                </div>
                                {item.folderPathObj.showCoProducts && (
                                    <div className='last'>
                                        <button
                                            id='openFolderButton'
                                            className='btn btn-default border-0 onTop'
                                            onClick={e => {
                                                folderButtonClick(e, idx);
                                            }}>
                                            <FaIcon className='fa-lg whiteFont' icon='folder' />
                                        </button>
                                    </div>
                                )}
                            </div>
                            {(line.attrib.OpDescription?.length > 0)  && (
                                <div className='d-flex justify-content-between item'>
                                    {line.attrib.OpDescription}
                                </div>
                            )}
                            <div className='d-flex justify-content-between item'>
                                <div className='d-inline-flex'>{line.description}</div>
                            </div>
                        </div>
                    ))}
                </>
            );
        };
        if (props.isAccordion) {
            return (
                <div className='item accordion' id={'accordion_CoProducts_' + collapsibleBody}>
                    <>
                        <div
                            className='d-flex flex-row item'
                            id={'accordionHeading_' + collapsibleBody}>
                            <div
                                className='mr-1'
                                data-toggle='collapse'
                                data-target={'#' + collapsibleBody}
                                aria-controls={collapsibleBody}
                                onClick={e => onAccordionClick(e)}>
                                <FaIcon
                                    className='far fa-lg'
                                    icon={isCollapsed ? 'plus-square' : 'minus-square'}
                                />
                            </div>
                            <div>{item.itemId}</div>
                            <div className='last'>{item.lineDescription}</div>
                        </div>
                        <div
                            id={collapsibleBody}
                            className='collapse w-100 m-0 p-0'
                            aria-labelledby={'accordionHeading_' + collapsibleBody}
                            data-parent={'#accordion_CoProducts_' + collapsibleBody}>
                            <CoProductsBody />
                        </div>
                    </>
                </div>
            );
        }
        return (
            <>
                <CoProductsBody />
            </>
        );
    };

    return (
        <>
            <div
                className={'itemMaster ' + props.activeItem}
                data-idx={idx}
                onClick={() => {
                    props.clickFn(props.row);
                }}>
                <div className='d-flex justify-content-between item'>
                    <div className='d-inline-flex'>
                        <div className={item.hasCoProducts ? 'text-warning' : ''}>
                            {item.prodOrderId}
                        </div>
                        {item.salesOrder && <div>{item.salesOrder}</div>}
                        <div>
                            {item.goodQty} / {item.oprSchedQty} {item.unit}
                        </div>
                        {showScrap() && (
                            <div>
                                {item.scrapQty} {item.unit}
                            </div>
                        )}
                    </div>
                    <div className='oprStatus last'>{item.oprStatus}</div>
                </div>
                <div className='d-flex justify-content-between item'>
                    {item.routeDescription !== undefined && item.routeDescription !== '' && (
                        <div className='d-inline-flex'>
                            <div>{item.routeDescription}</div>
                        </div>
                    )}
                    {item.folderPathObj.show && !item.folderPathObj.showCoProducts && (
                        <div className='last'>
                            <button
                                id='openFolderButton'
                                className='btn btn-default border-0 onTop'
                                onClick={e => {
                                    folderButtonClick(e);
                                }}>
                                <FaIcon className='fa-lg whiteFont' icon='folder' />
                            </button>
                        </div>
                    )}
                </div>
                <div className='d-flex justify-content-between item dimmer'>
                    <div className='d-inline-flex'>
                        <div>{item.plannedDt}</div>
                        {(item.oprSchedMinutes !== undefined || item.oprSchedMinutes !== null) &&
                            currentWrkCtr.flags !== undefined &&
                            currentWrkCtr.flags.find(flag => flag === 'FEShowSchedMinutes') !==
                                undefined && <div>{item.normTime}</div>}
                    </div>
                </div>
                {!item.hasCoProducts && (
                    <div className='d-flex flex-row item'>
                        <div className='flex-nowrap'>{item.itemId}</div>
                        <div className='last'>{item.lineDescription}</div>
                    </div>
                )}
                {item.attrib == null ? (
                    ''
                ) : (
                    <div className='d-flex flex-row item'>
                        <div>{item.attrib.Customer || ''}</div>
                    </div>
                )}
                <CoProducts item={item} isAccordion={props.isAccordion} idx={props.row.key} />
            </div>
            {showFolderView && <FolderView uri={folderPath} />}
        </>
    );
};

export default ListItem;
