import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/locales/{{ns}}_{{lng}}.json'
        },
        detection: {
            order: ['localStorage','querystring'],
            lookupLocalStorage: 'usrLng',
            lookupQuerystring:'lng'
        },
        defaultNS: 'common',
        ns:['common'],
        lng: 'et',
        fallbackLng:'et',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: function(value, format) {
                if (format === 'capitalize'){
                    return typeof value === 'string' ? value.charAt(0).toUpperCase() + value.slice(1) : value;
                }
                if (format === 'uppercase') {
                    return typeof value === 'string' ? value.toUpperCase() : value;
                }
            }
        },
        react: {
            wait: true
        },
    });

export default i18n;
