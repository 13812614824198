// import {useEffect} from 'react';
import dateSort from '../../Helpers/dateSort';
import { dateInRange } from '../../Helpers/timeUtils';
export const getRowHeight = item => {
    let heightDiff = 1;
    const adder = (item.itemId + item.lineDescription).length;

    if (window.visualViewport.width >= 1200) {
        heightDiff = 60;
    }
    if (window.visualViewport.width < 1200 && window.visualViewport.width > 1024) {
        heightDiff = 40;
    }
    if (window.visualViewport.width <= 1024) {
        heightDiff = 26;
    }
    if (window.visualViewport.width <= 896) {
        heightDiff = 16;
    }
    if (window.visualViewport.width <= 768) {
        heightDiff = 6;
    }
    const custRowAdd = item.attrib?.Customer != null ? 20 : 0;

    return 180 + (parseInt(adder / heightDiff) + 1) * 20 + custRowAdd;
};

export const transformNewListData = ({ listData = [], setData }) => {
    if (listData != null) {
        const localData = listData
            .map(d => {
                d.key = d.prodOrderId + d.oprNo;
                d.rowHeight = getRowHeight(d);
                return d;
            });
        if (setData == null) return localData;
        setData(localData.sort(dateSort));
    }
};
