import React, { useContext } from 'react';
import CloseWindowBtn from '../LeftPanel/CommandButtons/CloseWindowButton';
import { DashboardCtx } from '../AppContext/DashboardStore';

function Header(props) {
    const { dashActions } = useContext(DashboardCtx);
    const defaultCloseBtnCB = () => {
        dashActions.closeCompInRightPanel({});
    };

    const title = props.title;
    const showCloseBtn = props.btnClose || false;
    const btnOnClick = props.btnCloseOnClick || defaultCloseBtnCB;

    const getClass = () => {
        const popUp = props.popUp || false;
        const nonCont = props.nonCont || false;
        let result = 'row ';
        if (popUp) result += 'inPopUp ';
        if (!nonCont) result += 'leftAndRightNavRow ';
        return result;
    };

    return (
        <div className={getClass()} id='plannedNavRow'>
            <div className='col d-sm-flex d-md-flex d-lg-flex d-xl-flex align-items-sm-end align-items-lg-end leftAndRightNavColumn'>
                <h3
                    className={
                        'd-md-flex d-lg-flex flex-grow-1 align-items-md-end align-items-xl-end justify-content-between' +
                        (props.nonCont ? '' : ' leftAndRightNavHeading')
                    }
                    id='plannedNavHeading'>
                    {title}{' '}
                    {showCloseBtn === true && <CloseWindowBtn onClick={e => btnOnClick(e)} />}
                </h3>
            </div>
        </div>
    );
}

export default Header;
