import ApiServiceGet, { ApiServiceGetActions } from '../ApiService/ApiServiceGet_new.jsx';
import createWrkCtrMembersArr from './createWrkCtrMembersArr';

const loadWorkcenterInfo = async ({ state, wrkCtr, userRole = '', companyUsers = [] }) => {
    let workcenter = wrkCtr || state.currentWrkCtr.id;
    const result = await Promise.all([
        ApiServiceGet({
            state,
            action: ApiServiceGetActions.getWorkcenterInfo,
            props: { workcenter },
        }),
        ApiServiceGet({
            state,
            action: ApiServiceGetActions.getWorkcenterPlannedTime,
            props: {
                userRole: userRole,
                workcenter,
            },
        }),
    ]);
    const wrkCtrInfo = result[0].data[0];
    const plannedTime = result[1].data.totalAvailableMinutes;

    const membersArr = createWrkCtrMembersArr({
        members: wrkCtrInfo.members || {},
        users: companyUsers || [],
    });
    wrkCtrInfo.members = [...membersArr];

    return { ...wrkCtrInfo, plannedTime };
};

export default loadWorkcenterInfo;
