const rightPanelCompLogic = (compArrState, rightPanelCompObj) => {
    let compArr = [...compArrState];
    if (rightPanelCompObj === undefined) return compArr;

    if (
        compArr.length > 0 &&
        compArr.find(comp => comp.name === rightPanelCompObj.name) !== undefined
    )
        return compArr;

    if (compArr.length === 1 && compArr[0].name === undefined) compArr.pop();
    compArr.unshift(rightPanelCompObj);
    if (compArr.length > 3) compArr.pop();

    return compArr;
};

export const closeRightPanel = compArrState => {
    let compArr = [...compArrState];
    if (compArr.length > 1) compArr.shift();
    return compArr;
};

export default rightPanelCompLogic;
