import React, { useRef, useState } from 'react';
import WorkItemsView from '../WorkItemsView/WorkItemsView';
import useLocale from '../../components/Helpers/locale';
import NonContFooter from '../NonContFooter/NonContFooter';
import { useAlert } from 'react-alert';
import { NONCONT_VIEW_ID } from '../Core/Core';

const UpdateWorkItem = props => {
    const items = props.itemsSelected;
    const [isReady, setReady] = useState(false);
    const { t } = useLocale();

    const doneBtnRef = useRef();
    const hourQtyInputRef = useRef();
    const customAlert = useAlert();

    const updateRdy = e => {
        e.preventDefault();
        doneBtnRef.current.value = !isReady;
        setReady(!isReady);
    };

    const anyWorkFinishable = () => {
        for (let item of items) if (item.canStart) return true;
        return false;
    };

    const refValue = ref => {
        if (ref === undefined) return null;
        if (ref.current && ref.current.value) return ref.current.value;
        return 0;
    };

    const submit = e => {
        e.preventDefault();
        const hourQtyInput = Number(refValue(hourQtyInputRef));

        if (hourQtyInput < 0) {
            customAlert.error('Submitted hours cannot be negative');
            return;
        }
        const assignedTimePerItem = Number(((hourQtyInput * 60) / items.length).toFixed(2));
        const isFinished = refValue(doneBtnRef) === 'true';

        if (assignedTimePerItem === 0.0 && !isFinished) {
            customAlert.show('At least 1 field must not be empty');
            return props.toggleActiveView(0);
        }

        let newData = [...props.data];
        items.forEach(item => {
            const index = newData.indexOf(item);
            if (index === -1) throw new Error('tried to modify non-existeng item');
            let updated = false;
            if (assignedTimePerItem > 0.0) {
                item.additionalTime = assignedTimePerItem;
                updated = true;
            } else if (assignedTimePerItem === 0.0 && item.additionalTime !== undefined) {
                delete item.assignedTimePerItem;
                updated = true;
            }

            if (isFinished && item.canStart) {
                item.additionalFinished = true;
                updated = true;
            } else if (!isFinished && item.canStart && item.additionalFinished !== undefined) {
                delete item.additionalFinished;
                updated = true;
            }
            if (updated) {
                newData.splice(index, 1, item);
            }
        });
        props.setForUpdate([...newData]);

        props.toggleActiveView(2, true);
    };

    return (
        <>
            <div className='card-body leftAndRightNavHeading m-0 p-0 d-flex flex-column'>
                <div className='card-title d-flex container justify-content-between p-0 pt-1 pb-2 leftAndRightNavHeading m-0'>
                    <div className='column col-7 align-middle'>
                        <div className='row align-middle h-50 pt-1'>{t('header.submitTime')}</div>
                        {anyWorkFinishable() && (
                            <div className='row align-middle h-50 pt-1'>
                                {t('header.isJobDone')}
                            </div>
                        )}
                    </div>
                    <div className='column col-4 mx-auto'>
                        <input
                            type='number'
                            step='0.25'
                            min='0'
                            className='form-control-plaintext border-white text-white text-center pr-1'
                            onClick={e => e.stopPropagation()}
                            placeholder={0.0}
                            ref={hourQtyInputRef}
                        />
                        {anyWorkFinishable() && (
                            <button
                                className={
                                    'btn btn-secondary border-white text-white w-100 mt-1' +
                                    (isReady ? ' bg-success' : ' bg-warning')
                                }
                                onClick={e => updateRdy(e)}
                                ref={doneBtnRef}>
                                <b>{(isReady ? t('yes') : t('no')).toUpperCase()}</b>
                            </button>
                        )}
                    </div>
                </div>
                <div className='card-text row d-flex overflow-auto justify-content-start mx-1'>
                    <WorkItemsView
                        data={items}
                        setSelected={props.setSelected}
                        itemsSelected={items}
                        editable={false}
                    />
                </div>
            </div>
            <NonContFooter
                viewID={NONCONT_VIEW_ID.UPDATE}
                toggleActiveView={props.toggleActiveView}
                submit={submit}
            />
        </>
    );
};

export default UpdateWorkItem;
