import React, { useContext, useEffect, useState } from 'react';
import { DashboardCtx } from '../../AppContext/DashboardStore';
import { GlobalCtx } from '../../AppContext/GlobalStore';
import Header from '../Header';
import OfflineTimer from '../../OfflineTimer/OfflineTimer';
import { formatTime } from '../../Helpers/timeUtils';
import useLocale from '../../Helpers/locale';
import ApiServiceGet, { ApiServiceGetActions } from '../../ApiService/ApiServiceGet_new';

export const panelCompObj = { name: 'workcenterinfo' };

const WorkingTime = props => {
    let workingMinutes = 0;
    if (props.wrkCtr !== undefined && props.wrkCtr.currentDay !== null) {
        workingMinutes =
            props.wrkCtr.currentDay.workingMinutes >= 0
                ? props.wrkCtr.currentDay.workingMinutes
                : 0;
        if (
            (props.wrkCtr.status === 3 || props.wrkCtr.status === 2) &&
            Object.keys(props.wrkCtr.members).length > 0
        ) {
            return (
                <OfflineTimer
                    time={workingMinutes * 60}
                    timerOn={true}
                    format={'H[h] mm[min]'}
                    unit={'seconds'}
                />
            );
        }
    }
    return formatTime({ time: workingMinutes, unit: 'minutes', format: 'H[h] mm[min]' });
};

const IdleTime = props => {
    let idleMinutes = 0;
    if (props.wrkCtr !== undefined && props.wrkCtr.currentDay !== null) {
        idleMinutes =
            props.wrkCtr.currentDay.idleMinutes >= 0 ? props.wrkCtr.currentDay.idleMinutes : 0;
        if (props.wrkCtr.status === 1) {
            return (
                <OfflineTimer
                    time={idleMinutes * 60}
                    timerOn={true}
                    format={'H[h] mm[min]'}
                    unit={'seconds'}
                />
            );
        }
    }
    return formatTime({ time: idleMinutes, unit: 'minutes', format: 'H[h] mm[min]' });
};

const TotalTime = props => {
    let allMinutes = 0;
    if (props.wrkCtr !== undefined && props.wrkCtr.currentDay !== null) {
        let workingMinutes =
            props.wrkCtr.currentDay.workingMinutes >= 0
                ? props.wrkCtr.currentDay.workingMinutes
                : 0;
        let idleMinutes =
            props.wrkCtr.currentDay.idleMinutes >= 0 ? props.wrkCtr.currentDay.idleMinutes : 0;
        allMinutes = workingMinutes + idleMinutes;
        if (props.wrkCtr.status === 1 || props.wrkCtr.status === 2) {
            return (
                <>
                    <OfflineTimer
                        time={allMinutes * 60}
                        timerOn={true}
                        format={'H[h] mm[min]'}
                        unit={'seconds'}
                    />
                </>
            );
        }
    }
    return formatTime({ time: allMinutes, unit: 'minutes', format: 'H[h] mm[min]' });
};

// const PlannedTime = props => {
//     if (props.data !== undefined && props.data.totalSchedMinutes !== null) {
//         let plannedMinutes = props.data.totalSchedMinutes;
//         return formatTime(plannedMinutes);
//     }
//     return '';
// };

const Members = props => {
    // if (
    //     props.wrkCtr !== undefined &&
    //     props.names !== undefined &&
    //     props.names.length > 0 &&
    //     props.wrkCtr.members !== undefined &&
    //     props.wrkCtr.members !== null &&
    //     Object.keys(props.names.data).length > 0 &&
    //     Object.keys(props.wrkCtr.members).length > 0
    // ) {
    //     var names = [];
    //     for (var i = 0; i < Object.keys(props.names.data).length; i++) {
    //         for (var j = 0; j < Object.keys(props.wrkCtr.members).length; j++) {
    //             if (props.names.data[i].Id === Object.keys(props.wrkCtr.members)[j]) {
    //                 names.push(props.names.data[i].Name);
    //             }
    //         }
    //     }
    //     return names.map((item, idx) => (
    //         <li id={idx} key={idx}>
    //             <p className='infoText'>{item}</p>
    //         </li>
    //     ));
    // }
    if (props.wrkCtr === undefined) {
        return '';
    }
    const memberArr = props.names;
    if (!Array.isArray(memberArr)) {
        return '';
    }
    return memberArr.map((item, idx) => (
        <li id={idx} key={idx}>
            <p className='infoText'>{item.name}</p>
        </li>
    ));
};

const WorkcenterInfoComp = () => {
    const { dashState } = useContext(DashboardCtx);
    const { state } = useContext(GlobalCtx);
    const { t } = useLocale();
    const [initalLoad, setInitialLoad] = useState(true);
    const [currentWrkCtrObj, setCurrentWrkCtrObj] = useState(state.currentWrkCtr.info);

    useEffect(() => {
        const loadWrkCtrInfo = () => {
            ApiServiceGet({
                state,
                action: ApiServiceGetActions.getWorkcenterInfo,
                props: { workcenter: state.currentWrkCtr.id },
            }).then(result => {
                setCurrentWrkCtrObj(result.data[0]);
            });
        };
        if (!initalLoad) loadWrkCtrInfo();
        setInitialLoad(false);
    }, [state, dashState.reloadData, dashState.namesList]);
    return (
        <div className='overflow-auto'>
            <Header title={t('header.workcenterInfo')} />
            <div className='row plannedRow'>
                <div className='col' id='workcenterInfoColumn'>
                    <div className='row'>
                        <p className='infoText'>
                            {t('workcenterInfo.name')} {state.currentWrkCtr.name}
                        </p>
                    </div>
                    <div className='row'>
                        <p className='infoText'>
                            {t('workcenterInfo.plannedTime')}{' '}
                            {state.currentWrkCtr.info.plannedTime !== undefined &&
                                formatTime({
                                    time: state.currentWrkCtr.info.plannedTime,
                                    unit: 'minutes',
                                    format: 'H[h] mm[min]',
                                })}
                        </p>
                    </div>
                    <div className='row' id='teamRow'>
                        <p className='infoText'>{t('workcenterInfo.team')}</p>
                        <ul>
                            <Members
                                wrkCtr={state.currentWrkCtr.info}
                                names={dashState.namesList}
                            />
                        </ul>
                    </div>
                    <div className='d-flex flex-column justify-content-around'>
                        <div>
                            <div className='infoText d-flex'>
                                <p>{t('workcenterInfo.worktimeSum')}</p>
                                <div className='WrkCtrInfoTime'>
                                    <TotalTime wrkCtr={currentWrkCtrObj} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='infoText d-flex'>
                                <p>{t('workcenterInfo.worktimeProduction')}</p>
                                <div className='WrkCtrInfoTime'>
                                    <WorkingTime wrkCtr={currentWrkCtrObj} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='infoText d-flex'>
                                <p>{t('workcenterInfo.worktimePause')}</p>
                                <div className='WrkCtrInfoTime'>
                                    <IdleTime wrkCtr={currentWrkCtrObj} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const WorkcenterInfo = () => {
    const { dashState } = useContext(DashboardCtx);
    const rightPanelName =
        dashState.rightPanelComp[0] !== undefined ? dashState.rightPanelComp[0].name : '';
    if (rightPanelName === panelCompObj.name) return <WorkcenterInfoComp />;
    return null;
};

export default WorkcenterInfo;
