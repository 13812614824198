import { actionTypes } from './dashboardActions';
import rightPanelCompLogic, { closeRightPanel } from '../Helpers/rightPanelCompLogic';
import { panelCompObj as newWorkListCompObj } from '../RightPanel/Panels/WorkItemListNew';
import { panelCompObj as wrkCtrInfoCompObj } from '../RightPanel/Panels/WorkcenterInfo';

export const dashboardState = {
    selectedJob: {},
    rightPanelComp: [],
    btnClicked: '',
    activeJobList: [],
    hasClockedIn: false,
    startTime: 0,
    modalComp: { name: '' },
    namesList: [],
    initialized: false,
    reloadData: false,
    // reloadDone: true,
    reloadButtons: false,
    resetSearch: false,
    reloadHistory: false,
};

export const dashboardReducer = (state, action) => {
    Object.freeze(state);
    switch (action.type) {
        case actionTypes.INIT_DASHBOARD: {
            if (action.members === null) return state;
            const righPanelComp = action.isDevice ? wrkCtrInfoCompObj : newWorkListCompObj;
            return {
                ...state,
                rightPanelComp: [righPanelComp],
                namesList: action.members || [],
                initialized: true,
                reloadData: !state.reloadData,
                reloadHistory: !state.reloadHistory,
            };
        }
        case actionTypes.JOB_SELECTED:
            return jobSelected(state, action);

        case actionTypes.WORK_STATUS_CHANGE: {
            let newState = jobSelected(state, action);
            newState.reloadButtons = !state.reloadButtons;
            newState.reloadData = !state.reloadData;
            newState.resetSearch = !state.resetSearch;
            newState.reloadHistory = !state.reloadHistory;
            return newState;
        }

        case actionTypes.SET_RIGHT_PANEL_COMP: {
            const compArr = changeRightPanelComp(state, action);
            return { ...state, rightPanelComp: compArr };
        }

        case actionTypes.CLOSE_RIGHT_PANEL_COMP: {
            let newState = { ...state, rightPanelComp: closeRightPanel(state.rightPanelComp) };
            newState.reloadHistory = !state.reloadHistory;
            if (action.reloadList) newState.reloadData = !state.reloadData;
            if (action.unSelectJob) newState.selectedJob = {};
            return newState;
        }

        // case actionTypes.SET_BTN_CLICKED:
        //     return { ...state, btnClicked: action.btnClicked };

        case actionTypes.SET_ACTIVEJOB_LIST:
            return { ...state, activeJobList: action.activeJobList, reloadDone: true };

        case actionTypes.SET_USER_HAS_CLOCKED_IN: {
            let newState = {
                ...state,
                hasClockedIn: action.hasClockedIn,
                reloadData: !state.reloadData,
                startTime: action.startTimeSec || 0,
                reloadButtons: !state.reloadButtons,
                reloadHistory: !state.reloadHistory,
            };
            if (action.closePanel === true)
                newState.rightPanelComp = closeRightPanel(state.rightPanelComp);
            return newState;
        }

        case actionTypes.SET_MODAL_COMP: {
            let modalComp = action.modalComp || {};
            return { ...state, modalComp: modalComp };
        }

        case actionTypes.CHANGE_USER_LIST: {
            if (Array.isArray(action.membersArr) === false) return state;
            return { ...state, namesList: action.membersArr, reloadButtons: !state.reloadButtons, reloadHistory: !state.reloadHistory };
        }

        case actionTypes.RELOAD_LIST_DATA: {
            const newAction = { ...action, reloadData: true };
            return jobSelected(state, newAction);
        }

        // case actionTypes.RELOAD_DONE:
        //     return { ...state, reloadDone: true };
        default:
            return state;
    }
};

/* Helper functions start */
const changeRightPanelComp = (state, action) => {
    const res = rightPanelCompLogic(state.rightPanelComp, action.rightPanelCompObj);
    return res;
};

const jobSelected = (state, action) => {
    let newState = { ...state, selectedJob: action.selectedJob || {} };
    newState.reloadButtons = !state.reloadButtons;
    if (action.reloadData === true) {
        newState.reloadData = !state.reloadData;
        newState.reloadHistory = !state.reloadHistory;
    }
    return newState;
};

// const reloadData = state => {
//     return { ...state, reloadData: !state.reloadData, reloadDone: false };
// };

/* Helper functions end */
