import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Auth from './components/Login/AuthPage';
import './i18n';
import './index.css';
import 'react-responsive-modal/styles.css';

const DO_NOT_LOGIN = true;

runWithAdal(
    authContext,
    () => {
        // require('./indexApp.js');
        ReactDOM.render(
            <Router>
                <Route component={Auth} />
            </Router>,
            document.getElementById('root')
        );
    },
    DO_NOT_LOGIN
);
