import React, { createContext, useReducer } from 'react';
import { dashboardState, dashboardReducer } from './dashboardReducer';
import { useActions } from './dashboardActions';
import { applyDashboardMiddelware } from './middlewares';

const DashboardCtx = createContext(dashboardState);

const DashboardProvider = ({ children, state = dashboardState }) => {
    const [dashState, dispatch] = useReducer(dashboardReducer, state);

    const dashSuperDispatch = applyDashboardMiddelware(dispatch);
    const dashActions = useActions(dashState, dashSuperDispatch);

    return (
        <DashboardCtx.Provider value={{ dashState, dashSuperDispatch, dashActions }}>
            {children}
        </DashboardCtx.Provider>
    );
};

export { DashboardCtx, DashboardProvider, dashboardState };
