import { actionTypes } from './globalActions';
import { WorkplaceType } from '../Helpers/enums';

export const globalState = {
    currentCompany: {},
    currentWrkCtr: { name: '', id: '', isDevice: false },
    user: { authenticated: false, userRole: '' },
    wrkCtrs: [],
    companyUsers: [],
    companyStopReasons: [],
    userDataLoaded: false,
    isDevice: false,
    viewRoleSelect: false,
    viewWorkplaceSelect: false,
};

export const globalReducer = (state = globalState, action) => {
    Object.freeze(state);
    let wrkCtr = {};

    switch (action.type) {
        case actionTypes.SET_USER_DATA: {
            let newState = { ...state };
            newState.user = { ...action.user, authenticated: true };
            return newState;
        }

        case actionTypes.SET_OPEN_ROLE_SELECT_VIEW: {
            return { ...state, viewRoleSelect: true, user: { ...state.user, userRole: '' } };
        }

        case actionTypes.ROLE_SELECTED: {
            let newState = { ...state };
            newState.userDataLoaded = false;
            newState.viewRoleSelect = !action.hideRoleSelection;
            newState.user = { ...state.user, userRole: action.roleModule };
            newState.user.wrkCtrs = [...action.roleWorkplaces];
            if (action.wp !== '') {
                newState.user.defaultWorkplace = action.wp;
                newState.currentWrkCtr = { ...globalState.currentWrkCtr };
            }
            return newState;
        }

        case actionTypes.SET_CURRENT_COMPANY:
            return {
                ...state,
                currentCompany: action.company,
                companyUsers: action.users,
                wrkCtrs: action.wrkCtrs,
                companyStopReasons: action.stopReasons,
            };

        case actionTypes.SET_COMPANY_USERS:
            return { ...state, companyUsers: action.users };

        case actionTypes.SET_OPEN_WORKPLACE_SELECT_VIEW:
            return { ...state, viewWorkplaceSelect: true };

        case actionTypes.SITE_CHANGED: {
            return {
                ...state,
                currentCompany: globalState.currentCompany,
                user: { ...action.user, userRole: '' },
                currentWrkCtr: globalState.currentWrkCtr,
                userDataLoaded: false,
            };
        }

        case actionTypes.WRKCTR_CHANGED: {
            wrkCtr = { ...globalState.currentWrkCtr };
            wrkCtr.id = action.user.defaultWorkplace;
            return {
                ...state,
                user: action.user,
                currentWrkCtr: wrkCtr,
                userDataLoaded: false,
                viewWorkplaceSelect: false,
            };
        }

        case actionTypes.SET_WRKCTRS: {
            return setWrkCtrs(state, action.wrkCtrs);
        }

        case actionTypes.FINISH_INITAL_LOAD: {
            // if (action.wrkCtrs === undefined) return state;
            return setUserDataLoaded(state, action);
        }

        default:
            return state;
    }
};

// const getRoleCenters = (rolesArr, selectedModule) => {
//     if (rolesArr.length === 0) return [];
//     return rolesArr
//         .filter(role => role.FEModule === selectedModule)
//         .flatMap(role => role.WorkplaceIds);
// };

const setWrkCtrs = (state, wrkCtrs) => {
    return { ...state, wrkCtrs: [...wrkCtrs] };
};

const setUserDataLoaded = (state, action) => {
    let newState = {
        ...state,
        userDataLoaded: true,
    };
    let userWrkCtr = newState.user.wrkCtrs.find(
        wrkCtr => wrkCtr.id === action.currentWrkCtrInfo.wrkCtrId
    );
    if (userWrkCtr !== undefined) {
        userWrkCtr.info =
            action.currentWrkCtrInfo !== undefined ? { ...action.currentWrkCtrInfo } : {};
        newState.currentWrkCtr = { ...userWrkCtr };
    }
    newState.isDevice = newState.currentWrkCtr.workAllocType === WorkplaceType.DEVICE;
    return newState;
};
