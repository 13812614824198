import React, { useEffect } from 'react';
import useAxios from 'axios-hooks';

import ApiServiceGet, { ApiServiceGetActions } from '../../components/ApiService/ApiServiceGet_new'; //'../../components/ApiService/ApiServiceGet_new';
import useLocale from '../../components/Helpers/locale';

const sameDay = (fDate, sDate = new Date()) => {
    return (
        sDate.getFullYear() === fDate.getFullYear() &&
        sDate.getMonth() === fDate.getMonth() &&
        sDate.getDate() === fDate.getDate()
    );
};

const countDataTime = data => {
    let timeToday = 0.0;
    let timeTotal = 0.0;

    if (data === undefined || data.length === 0) return { timeToday, timeTotal };

    const today = new Date();

    for (let dayData of data) {
        const transDate = new Date(dayData.transDate);
        if (transDate === undefined) throw new Error('transDate cannot be undefined');
        if (sameDay(transDate, today)) {
            timeToday = Number(dayData.actionDurationMinutes);
        }
        timeTotal += Number(dayData.actionDurationMinutes);
    }

    timeTotal = (Number(timeTotal) / 60).toFixed(2);
    timeToday = (Number(timeToday) / 60).toFixed(2);
    return { timeToday, timeTotal };
};

const TimeTracking = props => {
    const state = props.state;
    const stateReload = props.stateReload;
    const { t } = useLocale();

    const reqUri = React.useMemo(
        () =>
            ApiServiceGet({
                state,
                action: ApiServiceGetActions.getLastWorkedHoursURI,
                props: { days: new Date().getDate() },
            }),
        [state.currentWrkCtr.id]
    );
    const [{ data }, refetch] = useAxios(reqUri);

    useEffect(() => {
        if (data !== null) refetch();
    }, [refetch, stateReload]);

    const { timeToday, timeTotal } = countDataTime(data);
    return (
        <>
            <h5 className='row justify-content-center pt-2'>
                {t('timeTracking.today')}: {timeToday} / {t('timeTracking.month')}: {timeTotal}
            </h5>
        </>
    );
};

export default TimeTracking;
