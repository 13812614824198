export const WorkplaceType = {
    EMPLOYEE: 0,
    DEVICE: 1,
};

export const FEModule = {
    MES: {
        id: 'mes',
        name: 'Tootmine',
    },
    WMS: {
        id: 'wms',
        name: 'Ladu',
    },
    QMS: {
        id: 'qms',
        name: 'Kvaliteet',
    },
};

export const CompanyFlags = {
    UserSelection: 'UserSimpleSelection',
};
export const WorkplaceFlags = {
    ListDefaultHidden: 'FESearchMandatory',
    ShowFolder: 'FEShowFolder',
};
export const ModalEnums = {
    position: {
        left: 'left',
        center: 'center',
        right: 'right',
        fullScreen: 'fullScreen',
    },
    height: {
        h100: 100,
        h75: 75,
        h50: 50,
        h25: 25,
    },
    width: {
        w100: 100,
        w75: 75,
        w50: 50,
        w25: 25,
    },
};
export const StopReason = {
    None: 0, //- not used.
    StopReason: 1, //- Pause work.
    BreakReason: 2, //- Terminate work.
    NotificationReason: 3, //- notification event.
    ScrapReason: 4, //- scrap reason.
};
export const WmsMenuItem = {
    Transfer: 'transfer',
    Counting: 'counting',
};
const Enums = {
    WorkplaceType,
    FEModule,
    CompanyFlags,
    WorkplaceFlags,
    ModalEnums,
    StopReason,
    WmsMenuItem,
};
export default Enums;
