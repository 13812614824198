import React, { useState, useContext } from 'react';
import ModalComp from 'react-responsive-modal';
import PropTypes from 'prop-types';
import { DashboardCtx } from '../AppContext/DashboardStore';
import { ModalEnums } from '../Helpers/enums';
import { GlobalCtx } from '../AppContext/GlobalStore';

const useModal = ({
    opened = false,
    position = ModalEnums.position.right,
    height = ModalEnums.height.h100,
    focusTrapped = true,
    showCloseBtn = true,
    width = undefined,
    popUpClass = true,
}) => {
    const [modalOpened, setModalOpened] = useState(opened);
    const { dashState, dashActions } = useContext(DashboardCtx);
    const { state } = useContext(GlobalCtx);

    const modalClass = () => {
        let classArray = [];
        if (popUpClass) classArray.push('popUp');
        switch (position) {
            case ModalEnums.position.right:
                classArray.push('right');
                break;
            case ModalEnums.position.fullScreen:
                classArray.push('full-screen');
                break;
            case ModalEnums.position.center:
                classArray.push('center');
                break;
            case ModalEnums.position.left:
                classArray.push('left');
                break;
            default:
                break;
        }
        switch (width) {
            case ModalEnums.width.w100:
                classArray.push('w-100');
                break;
            case ModalEnums.width.w75:
                classArray.push('w-75');
                break;
            case ModalEnums.width.w50:
                classArray.push('w-50');
                break;
            case ModalEnums.width.w25:
                classArray.push('w-25');
                break;
            default:
                break;
        }
        switch (height) {
            case ModalEnums.height.h100:
                classArray.push('h-100');
                break;
            case ModalEnums.height.h75:
                classArray.push('h-75');
                break;
            case ModalEnums.height.h50:
                classArray.push('h-50');
                break;
            case ModalEnums.height.h25:
                classArray.push('h-25');
                break;
            default:
                break;
        }
        if (state.currentWrkCtr.flags?.indexOf('NonContinuous') !== -1) classArray.push('nonCont');
        return classArray;
    };
    const modalClosed = () => {
        if (dashState.modalComp.name !== '') dashActions.closeModal();
        setModalOpened(false);
    };

    const Modal = props => {
        const [childElement, setChildElement] = useState();
        const makeAChild = React.useCallback(() => {
            setChildElement(
                React.cloneElement(
                    props.children,
                    { opened: modalOpened, closeModal: modalClosed },
                    { ...props.children.children }
                )
            );
        }, [props.children]);
        React.useEffect(() => {
            if (modalOpened) makeAChild();
            // return () => setChildElement({});
        }, []);

        const closeIcon = (
            <svg width='32' height='32'>
                <rect
                    rx='6'
                    ry='6'
                    width='32'
                    height='32'
                    style={{ fill: '#dc3545', strokeWidth: 2 }}
                />
                <g fill='none' stroke='white' strokeWidth='3'>
                    <path strokeLinecap='round' d='M8 8 24 24' />
                    <path strokeLinecap='round' d='M8 24 24 8' />
                </g>
            </svg>
        );
        return (
            <ModalComp
                center
                open={modalOpened}
                onClose={modalClosed}
                focusTrapped={focusTrapped}
                closeOnOverlayClick={false}
                showCloseIcon={showCloseBtn}
                closeOnEsc={false}
                classNames={{ modal: modalClass() }}
                closeIcon={closeIcon}>
                {childElement}
            </ModalComp>
        );
    };

    return [Modal, setModalOpened];
};

useModal.propTypes = {
    opened: PropTypes.bool,
    position: PropTypes.string,
    compClsName: PropTypes.string,
};

export default useModal;
