import React from 'react';
// import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';

const CloseWindowBtn = props => {
    const localOnClick = e => {
        if (props.onClick !== undefined && typeof props.onClick === 'function') props.onClick(e);
    };
    return (
        <div className='nav justify-content-end zindex-100'>
            <button
                className='btn btn-xs btn-danger'
                onClick={e => {
                    localOnClick(e);
                }}>
                <span aria-hidden='true'>&times;</span>
            </button>
        </div>
    );
};

export default CloseWindowBtn;
