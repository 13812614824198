import React from 'react';
import useLocale from '../Helpers/locale';
import LoadAnim from './LoadAnim';

const DataLoading = () => {
    const { t } = useLocale();
    return <LoadAnim animtext={t('loadingData')} />;
};

export default DataLoading;
