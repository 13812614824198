import moment from 'moment';
import 'moment-duration-format';
import 'moment/locale/et';

export const timeFormatProps = { time: 0, format: 'H:mm', unit: 'seconds' };

export const formatTime = ({ time, format, unit }) => {
    const timeFormat = format || timeFormatProps.format;
    const timeUnit = unit || timeFormatProps.unit;
    return moment.duration(time, timeUnit).format(timeFormat, { trim: false }, { trunc: true });
};

const changeDate = ({ date, add, sub, number, unit }) => {
    if (date !== undefined && typeof date !== 'string') {
        return null;
    }
    if (!moment(date).isValid()) return null;

    if (add) return moment(date).add(number, unit);
    if (sub) return moment(date).subtract(number, unit);

    return date;
};

export const dateInFuture = ({ dateString, number, unit }) => {
    return changeDate({ date: dateString, add: true, number, unit });
};

export const dateInPast = ({ dateString, number, unit }) => {
    return changeDate({ date: dateString, sub: true, number, unit });
};

export const dateInRange = ({ dateString, before, after }) => {
    let beforeRange = before === undefined;
    let afterRange = after === undefined;

    if (dateString !== undefined && typeof dateString !== 'string') return true;
    if (dateString !== undefined && !moment(dateString).isValid()) return true;
    const date = moment(dateString);

    if (before !== undefined) {
        beforeRange = date.isSameOrAfter(dateInPast({ number: before.number, unit: before.unit }));
    }
    if (after !== undefined) {
        afterRange = date.isSameOrBefore(dateInFuture({ number: after.number, unit: after.unit }));
    }

    return beforeRange && afterRange;
};

export const splitDateTime = ({ dateTime }) => {
    return {
        date: moment(dateTime).format('DD.MM.YYYY'),
        time: moment(dateTime).format('HH:mm'),
    };
};

export default {
    formatTime: formatTime,
    dateInFuture: dateInFuture,
    dateInPast: dateInPast,
    splitDateTime: splitDateTime,
};
