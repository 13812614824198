export const actionTypes = {
    JOB_SELECTED: 'JOB_SELECTED',
    WORK_STATUS_CHANGE: 'WORK_STATUS_CHANGE',
    SET_RIGHT_PANEL_COMP: 'SET_RIGHT_PANEL_COMP',
    CLOSE_RIGHT_PANEL_COMP: 'CLOSE_RIGHT_PANEL_COMP',
    SET_BTN_CLICKED: 'SET_BTN_CLICKED',
    SET_ACTIVEJOB_LIST: 'SET_ACTIVEJOB_LIST',
    SET_USER_HAS_CLOCKED_IN: 'SET_USER_HAS_CLOCKED_IN',
    SET_MODAL_COMP: 'SET_MODAL_COMP',
    RELOAD_LIST_DATA: 'RELOAD_LIST_DATA',
    // RELOAD_DONE: 'RELOAD_DONE',
    CHANGE_USER_LIST: 'CHANGE_USER_LIST',
    SET_NAMES_LIST: 'SET_NAMES_LIST',
    INIT_DASHBOARD: 'INIT_DASHBOARD',
};

export const useActions = (state, dispatch) => ({
    initDashboard: ({ members, isDevice = false }) =>
        dispatch({
            type: actionTypes.INIT_DASHBOARD,
            members,
            isDevice,
        }),
    setJobSelected: selectedJob =>
        dispatch({
            type: actionTypes.JOB_SELECTED,
            selectedJob,
        }),
    workStatusChanged: () => {
        dispatch({
            type: actionTypes.WORK_STATUS_CHANGE,
        });
    },
    setJobUnSelected: (reloadData = false) => {
        dispatch({ type: actionTypes.JOB_SELECTED, reloadData });
    },
    setCompToRightPanel: rightPanelCompObj => {
        dispatch({
            type: actionTypes.SET_RIGHT_PANEL_COMP,
            rightPanelCompObj,
        });
    },
    closeCompInRightPanel: ({ reloadList = false, unSelectJob = false }) => {
        dispatch({
            type: actionTypes.CLOSE_RIGHT_PANEL_COMP,
            reloadList,
            unSelectJob,
        });
    },
    reloadListData: () => {
        dispatch({
            type: actionTypes.RELOAD_LIST_DATA,
        });
    },
    setBtnClicked: btnClicked => {
        dispatch({
            type: actionTypes.SET_BTN_CLICKED,
            btnClicked,
        });
    },
    setActivceJobList: activeJobList => {
        dispatch({
            type: actionTypes.SET_ACTIVEJOB_LIST,
            activeJobList,
        });
    },
    setHasClockedIn: ({ hasClockedIn, startTimeSec, closePanel }) => {
        dispatch({
            type: actionTypes.SET_USER_HAS_CLOCKED_IN,
            hasClockedIn,
            startTimeSec,
            closePanel,
        });
    },
    userListChanged: ({ membersArr }) => {
        dispatch({ type: actionTypes.CHANGE_USER_LIST, membersArr });
    },
    openModal: modalComp => {
        dispatch({
            type: actionTypes.SET_MODAL_COMP,
            modalComp,
        });
    },
    closeModal: () => {
        dispatch({
            type: actionTypes.SET_MODAL_COMP,
        });
    },
});

export default useActions;
