import React from 'react';
import LoadAnim from '../../components/LoadAnim/LoadAnim';
const TransactionLog = React.lazy(() => import('../../components/TransactionLog/TransactionLog'));

const Log = props => {
    return (
        <React.Suspense fallback={<LoadAnim animtext='Valmistame andmeid ette' />}>
            <TransactionLog
                globalState={props.globalState}
                dashState={props.dashState}
                toggleActiveView={props.toggleActiveView}
            />
        </React.Suspense>
    );
};

export default Log;
