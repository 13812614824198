import { useEffect } from 'react';
import packageJson from '../../../package.json';
global.appVersion = packageJson.version;

const key = 'version';

const shouldForceRefresh = (respVer, localVer) => {
    if (respVer === localVer) return false;
    return true;
};

const CheckVersion = () => {
    useEffect(() => {
        // triggered on mount
        fetch('/meta.json')
            .then(response => response.json())
            .then(meta => {
                const latestVer = meta.version.toString();
                const latestBuildDate = meta.buildDate.toString();
                const currentVer = global.appVersion;
                const currentBuildDate = localStorage.getItem(key);
                console.log('Checking build dates: ' + currentBuildDate + '/' + latestBuildDate);
                if (currentBuildDate === null || currentBuildDate === undefined) {
                    localStorage.setItem(key, latestBuildDate);
                    return;
                } else {
                    if (
                        currentBuildDate !== latestBuildDate ||
                        shouldForceRefresh(latestVer, currentVer)
                    ) {
                        console.log('>? ' + (currentBuildDate === latestBuildDate));
                        if (currentBuildDate !== latestBuildDate)
                            localStorage.setItem(key, latestBuildDate);
                        window.location.href = window.location.href + '?eraseCache=true';
                        window.location.reload();
                    }
                }
            });
    }, []);

    return null;
};

export default CheckVersion;
