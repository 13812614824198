import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePreciseTimer from '../Helpers/usePreciseTimer';
import { formatTime, timeFormatProps } from '../Helpers/timeUtils';

const OfflineTimer = props => {
    const [timeAsSeconds, setTimeAsSeconds] = useState(0);
    const hasClockedIn = props.timerOn || false;
    let startTime = props.time || 0;
    const format = props.format || timeFormatProps.format;
    const timeUnit = props.unit || timeFormatProps.unit;
    let interval = 1000;

    const setTime = React.useCallback(
        clockedIn => {
            if (clockedIn === true) {
                if (timeAsSeconds === 0 && startTime !== 0) {
                    setTimeAsSeconds(startTime);
                }
            } else {
                setTimeAsSeconds(0);
            }
        },
        [startTime, timeAsSeconds]
    );

    useEffect(() => {
        setTime(hasClockedIn);
    }, [hasClockedIn, setTime]);

    const incrementTime = sec => {
        const newTime = timeAsSeconds + sec;
        setTimeAsSeconds(newTime);
    };

    usePreciseTimer(incrementTime, interval, hasClockedIn);

    return <>{formatTime({ time: timeAsSeconds, format: format, unit: timeUnit })}</>;
};

OfflineTimer.propTypes = {
    timerOn: PropTypes.bool,
    startTime: PropTypes.number,
};

export default OfflineTimer;
