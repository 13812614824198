import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { withAdalLoginApi } from '../../adalConfig';
import App from '../../App';
import LoadAnim from '../LoadAnim/LoadAnim';
import FrontPage from '../Login/FrontPage';

const AuthPage = () => {
    return (
        <>
            <FrontPage />
        </>
    );
};

const Logout = () => {
    const [reDirect, setReDirect] = useState(false);

    useEffect(() => {
        const redirectToLogin = () => {
            setReDirect(!reDirect);
        };
        setTimeout(redirectToLogin, 3000);
    }, []);

    return (
        <>
            <LoadAnim animtext='Login välja' />
            {reDirect && <Redirect to='/' />}
        </>
    );
};

const OnLoggin = error => {
    let msg = <LoadAnim animtext='Autentimine käib' />;

    if (error !== undefined && Object.keys(error).length > 0) msg = JSON.stringify(error);

    return <>{msg}</>;
};

const Auth = () => {
    const ProtectedApp = withAdalLoginApi(
        App,
        () => <OnLoggin />,
        error => <OnLoggin error={error} />
    );
    return (
        <>
            <Route path='/app' component={ProtectedApp} />
            <Route path='/' exact component={AuthPage} />
            <Route path='/logout' component={Logout} />
        </>
    );
};

export default Auth;
