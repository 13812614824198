import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal';

export const adalConfig = {
    tenant: process.env.REACT_APP_AUTH_TENANT,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    endpoints: {
        api: process.env.REACT_APP_AUTH_CLIENT_ID,
    },
    cacheLocation: 'localStorage',
    postLogoutRedirectUri: window.location.origin + '/logout',
    redirectUri: window.location.origin + '/app', //siia saaks siis panna App'i lehe
    popup: false,
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const isAuthenticated = () => {
    return false;
};

export const adalToken = async request => {
    return await adalGetToken(authContext, adalConfig.endpoints.api).then(token => {
        request.headers.authorization = `Bearer ${token}`;
        return request;
    });
};

export const adalAddApiHeaderAuht = header => {
    return adalGetToken(authContext, adalConfig.endpoints.api).then(token => {
        const h = header || {};
        h.headers = {
            ...h.headers,
            Authorization: `Bearer ${token}`,
        };
        return h;
    });
};

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
