import { useTranslation } from 'react-i18next';

const useLocale = () => {
    const { t, i18n } = useTranslation('common');
    const locales = [
        { id: 'et', name: 'ET' },
        { id: 'en', name: 'EN' },
    ];
    return { t, i18n, locales };
};

export default useLocale;
