import React, { useState, useEffect } from 'react';
import WorkItem from '../WorkItem';
import useLocale from '../../components/Helpers/locale';

const WorkItemsView = props => {
    const [data, setData] = useState(props.data);
    const isEditable = props.editable;
    const { t } = useLocale();

    useEffect(() => {
        if (props.data !== data) setData(props.data);
    }, [props.data]);

    const updateSelected = (e, item) => {
        e.stopPropagation();
        const result = [...props.itemsSelected];
        const itemIndex = result.indexOf(item);
        if (result === undefined) throw new Error('SelectedList not found');

        if (itemIndex === -1) {
            result.push(item);
        } else {
            result.splice(itemIndex, 1);
        }
        props.setSelected(result);
    };
    return (
        <div className='col m-0'>
            {data === undefined || data.length === 0 ? (
                <>
                    <p>{t('workItemList.' + (props.emptyMsg || 'emptyList'))}</p>
                </>
            ) : (
                <>
                    <ul id='plannedList' className='leftAndRightList m-0'>
                        {data.map((item, idx) => (
                            <WorkItem
                                key={idx}
                                itemsSelected={props.itemsSelected}
                                item={item}
                                updateSelected={updateSelected}
                                isEditable={isEditable}
                            />
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default WorkItemsView;
