export const actionTypes = {
    // SET_CURRENT_WRKCTR: 'SET_CURRENT_WRKCTR',
    SET_USER_DATA: 'SET_USER_DATA',
    SITE_CHANGED: 'SITE_CHANGED',
    WRKCTR_CHANGED: 'WRKCTR_CHANGED',
    SET_WRKCTRS: 'SET_WRKCTRS',
    SET_COMPANY_USERS: 'SET_COMPANY_USERS',
    FINISH_INITAL_LOAD: 'FINISH_INITAL_LOAD',
    SET_OPEN_ROLE_SELECT_VIEW: 'SET_OPEN_ROLE_SELECT_VIEW',
    ROLE_SELECTED: 'ROLE_SELECTED',
    SET_OPEN_WORKPLACE_SELECT_VIEW: 'SET_OPEN_WORKPLACE_SELECT_VIEW',
    SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY',
};

export const useActions = (state, dispatch) => ({
    setUserData: (user = {}) =>
        dispatch({
            type: actionTypes.SET_USER_DATA,
            user,
        }),
    viewRoleSelect: () => {
        dispatch({
            type: actionTypes.SET_OPEN_ROLE_SELECT_VIEW,
        });
    },
    roleSelected: ({ roleModule, hideRoleSelection = true, wp = '', roleWorkplaces = [] }) => {
        dispatch({
            type: actionTypes.ROLE_SELECTED,
            roleModule,
            hideRoleSelection,
            wp,
            roleWorkplaces,
        });
    },
    setCompanyData: ({ company, users = [], wrkCtrs = [], stopReasons = [] }) => {
        dispatch({
            type: actionTypes.SET_CURRENT_COMPANY,
            company,
            users,
            wrkCtrs,
            stopReasons,
        });
    },
    setCompanyUser: ({ users }) => {
        dispatch({
            type: actionTypes.SET_COMPANY_USERS,
            users,
        });
    },
    viewWorkplaceSelect: () => {
        dispatch({
            type: actionTypes.SET_OPEN_WORKPLACE_SELECT_VIEW,
        });
    },
    wrkCtrChanged: ({ user }) =>
        dispatch({
            type: actionTypes.WRKCTR_CHANGED,
            user,
        }),
    siteChanged: ({ user }) => dispatch({ type: actionTypes.SITE_CHANGED, user }),
    setWrkCtrs: wrkCtrs => dispatch({ type: actionTypes.SET_WRKCTRS, wrkCtrs }),
    finishInitalDataLoad: ({ currentWrkCtrInfo }) => {
        dispatch({
            type: actionTypes.FINISH_INITAL_LOAD,
            currentWrkCtrInfo,
        });
    },
});

export default useActions;
