import { authContext } from '../../adalConfig';
// import { emptyStore } from '../LocalStorage/LocalStorage';
import store from 'store2';

const logout = () => {
    store.clearAll();
    authContext.logOut();
};

export default logout;
