import { actionTypes } from './globalActions';
import ApiServiceGet from '../ApiService/ApiServiceGet';
import store from 'store2';
import logout from '../Helpers/logout';

// Global store middleware
const applyGlobalMiddelware = dispatch => action => {
    try {
        switch (action.type) {
            case actionTypes.SET_USER_DATA:
                setUserData({ dispatch, action });
                break;
            case actionTypes.SITE_CHANGED:
            case actionTypes.WRKCTR_CHANGED:
                dispatch(action);
                if (action.user !== null && action.user !== undefined) {
                    store.set('user', action.user);
                }
                break;
            case actionTypes.ROLE_SELECTED:
                {
                    dispatch(action);
                    let newUser = store.get('user');
                    newUser.userRole = action.roleModule;
                    if (action.wp) newUser.DefaultWorkplace = action.wp;

                    store.set('user', newUser);
                }
                break;
            default:
                dispatch(action);
        }
    } catch {
        logout();
    }
};

// Dashboard store middleware
const applyDashboardMiddelware = dispatch => action => {
    if (dispatch !== null || dispatch !== undefined) {
        dispatch(action);
    } else {
        switch (action.type) {
            default:
                return null;
        }
    }
};

// helper methods
const setUserData = async props => {
    let user = {};
    user = store.get('user');
    if (user !== null) {
        props.dispatch({ ...props.action, user });
        return;
    }

    let userData = [];
    userData = await ApiServiceGet.loadUserData().then(
        result => result.data,
        error => console.log(error) //TODO use react-alert
    );

    for (let userD of userData) await userRoleModules(userD);

    store.set('userData', userData);
    const defaultDataArr = userData.filter(data => {
        return data.defaultCompany;
    });
    if (defaultDataArr.length === 0) {
        logout();
        return;
    }
    user = {
        ...defaultDataArr[0],
        authenticated: true,
    };
    store.set('user', user);
    props.dispatch({ ...props.action, user });
};

const userRoleModules = async user => {
    const resultArr = await Promise.all([
        ApiServiceGet.loadRolesData({ company: user.companyId }),
        ApiServiceGet.workCenters({ company: user.companyId }),
    ]);

    const rolesData = resultArr[0].data;
    if (user !== undefined && rolesData !== undefined) {
        user.roles.map(role => {
            const d = rolesData.find(data => data.id === role.roleId);
            return (role.FEModule = d.feModule || 'mes');
        });
        // eslint-disable-next-line require-atomic-updates
        user.roleModules = [...new Set(user.roles.map(role => role.FEModule))];
        // eslint-disable-next-line require-atomic-updates
        user.userRole = user.roles.find(role =>
            role.workplaceIds.includes(user.defaultWorkplace)
        ).FEModule;
    }

    const allWrkCtrs = resultArr[1].data;
    // eslint-disable-next-line require-atomic-updates
    user.wrkCtrs = userRoleWorkplaces({ user, allWrkCtrs });
};

const userRoleWorkplaces = ({ user, allWrkCtrs }) => {
    const currentRoleWrkplaces = user.roles
        .filter(role => role.roleId === user.defaultRole)
        .flatMap(role => role.workplaceIds);
    const userWrkCtrs = allWrkCtrs.filter(wrkctr => currentRoleWrkplaces.includes(wrkctr.id));
    return userWrkCtrs;
};

export { applyGlobalMiddelware, applyDashboardMiddelware };
