import axios from 'axios';
import uuid from 'uuid';
import { APIURI } from '../../config';

let mesTransUri = '';
let qmsTransUri = '';
let wmsTransUri = '';

export const apiServicePut = props => {
    const state = props.state || {};
    const action = props.action;
    const reqProps = props.props || {};
    const defaultData = {
        companyId: state.currentCompany.companyId,
        workCenterId: state.currentWrkCtr.id,
        defaultRole: state.user.defaultRole,
        userId: state.user.id,
    };
    const companyId = defaultData.companyId;

    if (!Object.keys(PutActions).includes(action)) return '';

    initData(defaultData);
    const propsObj = { ...reqProps, defaultData };

    switch (action) {
        case PutActions.getNonContWorkUpdReq:
            return makeRequestObj({ obj: getNonContWorkUpdReq(propsObj), companyId });
        case PutActions.getWorkStartedReq:
            return makeRequestObj({ obj: getWorkStartedReq(propsObj), companyId });
        case PutActions.getWorkEndedReq:
            return makeRequestObj({ obj: getWorkEndedReq(propsObj), companyId });
        case PutActions.getSetupEndedReq:
            return makeRequestObj({ obj: getSetupEndedReq(propsObj), companyId });
        case PutActions.getQuantityAddedReq:
            return makeRequestObj({ obj: getQuantityAddedReq(propsObj), companyId });
        case PutActions.getQuantityFixReq:
            return makeRequestObj({ obj: getQuantityFixReq(propsObj), companyId });
        case PutActions.getShiftStartedReq:
            return makeRequestObj({ obj: getShiftStartedReq(propsObj), companyId });
        case PutActions.getShiftEndedReq:
            return makeRequestObj({ obj: getShiftEndedReq(propsObj), companyId });
        case PutActions.workPaused:
            return makeRequest({ ...workPaused(propsObj), companyId });
        case PutActions.workStarted:
            return makeRequest({ ...workStarted(propsObj), companyId });
        case PutActions.newWorkAdded:
            return makeRequest({ ...newWorkAdded(propsObj), companyId });
        case PutActions.setupStarted:
            return makeRequest({ ...setupStarted(propsObj), companyId });
        case PutActions.workerAddRemove:
            return makeRequest({ ...workerAddRemove(propsObj), companyId });
        case PutActions.workQualityRules:
            return makeRequest({ ...workQualityRules(propsObj), companyId });
        case PutActions.qualityRule:
            return makeRequest({ ...qualityRule(propsObj), companyId });
        case PutActions.sendQualityCheckList:
            return makeRequest({ ...sendQualityCheckList(propsObj), companyId });
        case PutActions.wMSInventoryForBOM:
            return makeRequest({ ...wMSInventoryForBOM(propsObj), companyId });
        case PutActions.sendMaterialMES:
            return makeRequest({ ...sendMaterialMES(propsObj), companyId });
        case PutActions.sendMaterialFeedback:
            return makeRequest({ ...sendMaterialFeedback(propsObj), companyId });
        case PutActions.sendMaterialTransfer:
            return makeRequest({ ...sendMaterialTransfer(propsObj), companyId });
        case PutActions.sendBOMChange:
            return makeRequest({ ...sendBOMChange(propsObj), companyId });
        default:
            return '';
    }
};

const initData = defaultData => {
    mesTransUri = `${APIURI}/api2/${defaultData.companyId}/mes/trans`;
    qmsTransUri = `${APIURI}/api2/${defaultData.companyId}/qms/`;
    wmsTransUri = `${APIURI}/api2/${defaultData.companyId}/wms/`;
};
const createHeader = contentType => {
    const header = {};
    const ct = contentType || { 'content-type': 'application/json' };
    header.headers = {
        ...ct,
    };
    return header;
};
const makeRequest = ({ uri, requestBody, companyId }) => {
    const headers = createHeader();
    const reqBody = {
        ...requestBody,
        id: requestBody.id || uuid(),
        actionTime: new Date().toISOString(),
        companyId,
    };
    return axios.put(uri, reqBody, headers);
};
// for new ting
const makeRequestObj = ({ obj, companyId }) => {
    let reqObject = {};
    if (obj?.reqBody) reqObject = obj.reqBody;
    const reqBody = {
        ...reqObject,
        id: reqObject.id || uuid(),
        actionTime: new Date().toISOString(),
        companyId,
    };
    return { ...obj, reqBody };
};

const workStarted = ({ workStarted, defaultData }) => {
    const requestBody = {
        prodOrderId: workStarted.prodOrderId,
        oprNo: workStarted.oprNo,
        type: workStarted.resStatus === 3 ? 2 : 10,
        emplId: defaultData.userId,
        roleId: workStarted.roleId,
        workPlaceId: workStarted.workPlaceId,
    };

    return { uri: mesTransUri, requestBody };
};
const getNonContWorkUpdReq = ({ workStarted, timeElapsed = 0, defaultData }) => {
    let reqBody;
    if (workStarted !== undefined) {
        reqBody = {
            prodOrderId: workStarted.prodOrderId,
            oprNo: workStarted.oprNo,
            type: workStarted.canStart ? 18 : 19,
            emplId: defaultData.userId,
            roleId: workStarted.roleId,
            workPlaceId: workStarted.workPlaceId,
            actionDurationMinutes: timeElapsed,
        };
    }

    return { uri: mesTransUri, reqBody };
};
const getWorkStartedReq = ({ workStarted, defaultData }) => {
    let reqBody = {};
    if (workStarted !== undefined) {
        reqBody = {
            prodOrderId: workStarted.prodOrderId,
            oprNo: workStarted.oprNo,
            type: workStarted.resStatus === 3 ? 2 : 10,
            emplId: defaultData.userId,
            roleId: workStarted.roleId,
            workPlaceId: workStarted.workPlaceId,
        };
    }

    return { uri: mesTransUri, reqBody };
};
const getWorkEndedReq = ({ workStarted, data, defaultData }) => {
    let reqBody;
    if (workStarted !== undefined && data !== undefined) {
        reqBody = {
            prodOrderId: workStarted.prodOrderId,
            oprNo: workStarted.oprNo,
            type: data.done ? 15 : 14,
            emplId: defaultData.userId,
            roleId: defaultData.defaultRole,
            workPlaceId: workStarted.workPlaceId,
            productionQty: data.prodQty,
            scrapQty: data.scrapQty === '' ? '0' : data.scrapQty,
            scrapReasonId: data.scrapReason,
            coProducts: data.coProducts,
        };
    }

    return { uri: mesTransUri, reqBody };
};
const getSetupEndedReq = ({ workStarted, data, defaultData }) => {
    let reqBody;
    if (workStarted !== undefined && data !== undefined) {
        reqBody = {
            prodOrderId: workStarted.prodOrderId,
            oprNo: workStarted.oprNo,
            type: workStarted.resStatus === 1 ? 21 : 4,
            emplId: defaultData.userId,
            roleId: workStarted.roleId,
            workPlaceId: workStarted.workPlaceId,
            coProducts: data.coProducts,
        };
    }
    return { uri: mesTransUri, reqBody };
};
const getQuantityAddedReq = ({ workStarted, data, defaultData }) => {
    let reqBody;
    if (workStarted !== undefined && data !== undefined) {
        reqBody = {
            prodOrderId: workStarted.prodOrderId,
            oprNo: workStarted.oprNo,
            type: 11,
            emplId: defaultData.userId,
            roleId: defaultData.defaultRole,
            workPlaceId: defaultData.workCenterId,
            productionQty: data.prodQty,
            scrapQty: data.scrapQty === '' ? '0' : data.scrapQty,
            scrapReasonId: data.scrapReason,
            coProducts: data.coProducts,
        };
    }
    return { uri: mesTransUri, reqBody };
};
const getQuantityFixReq = ({ workStarted, data, defaultData }) => {
    let reqBody;
    if (workStarted !== undefined && data !== undefined) {
        reqBody = {
            prodOrderId: workStarted.prodOrderId,
            oprNo: workStarted.oprNo,
            type: 16,
            emplId: defaultData.userId,
            roleId: defaultData.defaultRole,
            workPlaceId: defaultData.workCenterId,
            productionQty: data.prodQty,
            scrapQty: data.scrapQty === '' ? '0' : data.scrapQty,
            scrapReasonId: data.scrapReason,
            coProducts: data.coProducts,
        };
    }
    return { uri: mesTransUri, reqBody };
};
const workPaused = ({ workStarted, data, defaultData }) => {
    const requestBody = {
        prodOrderId: workStarted.prodOrderId,
        oprNo: workStarted.oprNo,
        type: workStarted.resStatus === 2 ? 3 : 12,
        emplId: defaultData.userId,
        roleId: defaultData.defaultRole,
        workPlaceId: workStarted.workPlaceId,
        stopReasonId: data.stopReason,
        stopReasonNotes: data.stopNotes,
        coProducts: data.coProducts,
    };

    return { uri: mesTransUri, requestBody };
};
const getShiftStartedReq = ({ defaultData }) => {
    const reqBody = {
        type: 90,
        emplId: defaultData.userId,
    };

    return { uri: mesTransUri, reqBody };
};
const getShiftEndedReq = ({ defaultData }) => {
    const reqBody = {
        type: 91,
        emplId: defaultData.userId,
    };

    return { uri: mesTransUri, reqBody };
};
const newWorkAdded = ({ workStarted, defaultData }) => {
    const requestBody = {
        prodOrderId: workStarted.prodOrderId,
        oprNo: workStarted.oprNo,
        type: 1,
        emplId: defaultData.userId,
        roleId: workStarted.roleId,
        workPlaceId: workStarted.workPlaceId,
    };

    return { uri: mesTransUri, requestBody };
};
const setupStarted = ({ workStarted, defaultData }) => {
    const requestBody = {
        prodOrderId: workStarted.prodOrderId,
        oprNo: workStarted.oprNo,
        type: 2,
        emplId: defaultData.userId,
        roleId: workStarted.roleId,
        workPlaceId: workStarted.workPlaceId,
    };

    return { uri: mesTransUri, requestBody };
};
const workerAddRemove = ({ workcenter, userId, addUser }) => {
    const requestBody = {
        type: addUser ? 92 : 93,
        emplId: userId,
        workPlaceId: workcenter,
    };

    return { uri: mesTransUri, requestBody };
};
const workQualityRules = ({ work, defaultData }) => {
    return qualityRule({ work, action: 'all', defaultData });
};
const qualityRule = ({ work, action, defaultData }) => {
    return {
        uri: `${qmsTransUri}qualityrule/search/${action}/?role=${defaultData.defaultRole}&workplace=${work.workPlaceId}`,
        requestBody: work,
    };
};
const sendQualityCheckList = ({ props, defaultData }) => {
    const requestBody = {
        companyId: defaultData.companyId,
        emplId: defaultData.userId,
        roleId: defaultData.defaultRole,
        workPlaceId: defaultData.workCenterId,
        ...props.transObj,
    };

    return { uri: `${qmsTransUri}trans`, requestBody };
};
const sendBOMChange = ({ boms, selectedItem, oprType }) => {
    const lines = boms.map(bom => {
        switch (oprType) {
            case 0:
                return {
                    oprNo: selectedItem.oprNo,
                    type: oprType,
                    bomLineId: bom.bomLineId,
                    bomLine: bom,
                };
            case 1: // This is a "Replace" oprNo, need to implement, possibly
                break;
            case 2:
                return {
                    oprNo: selectedItem.oprNo,
                    type: oprType,
                    bomLineId: bom.bomLineId,
                };
            default:
                throw new Error('Unknown operation type: ' + oprType);
        }
    });
    const requestBody = {
        lines: lines,
        id: selectedItem.prodOrderId,
        // actionTime: new Date().toISOString(),
        // companyId: user.companyId,
    };

    const uri = mesTransUri.replace('trans', 'prodbomchange');
    return { uri, requestBody };
};
const wMSInventoryForBOM = ({ inventDimArray, defaultData }) => {
    const requestBody = {
        SearchList: inventDimArray,
        FullMatchOnly: false,
        ReturnDetailedList: true,
    };

    return {
        uri: `${wmsTransUri}inventsum/search/list?role=${defaultData.defaultRole}&workplace=${defaultData.workCenterId}`,
        requestBody,
    };
};
const sendMaterialMES = ({ selectedJob, prodTransId, defaultData }) => {
    let requestBody = {
        prodOrderId: selectedJob.prodOrderId,
        oprNo: selectedJob.oprNo,
        type: 17,
        emplId: defaultData.userId,
        roleId: defaultData.defaultRole,
        workPlaceId: defaultData.workCenterId,
        id: prodTransId ? prodTransId : uuid(),
        actionTime: new Date().toISOString(),
        companyId: defaultData.companyId,
    };
    return { uri: mesTransUri, requestBody };
};
const sendMaterialFeedback = ({ transList, mesTransId }) => {
    const requestBody = {
        TransList: transList,
        prodTransId: mesTransId,
    };
    return { uri: `${wmsTransUri}trans`, requestBody };
};
const sendMaterialTransfer = ({ requestBody }) => {
    return { uri: `${wmsTransUri}trans`, requestBody };
};

export const PutActions = {
    workStarted: 'workStarted',
    getNonContWorkUpdReq: 'getNonContWorkUpdReq',
    getWorkStartedReq: 'getWorkStartedReq',
    getWorkEndedReq: 'getWorkEndedReq',
    getSetupEndedReq: 'getSetupEndedReq',
    getQuantityAddedReq: 'getQuantityAddedReq',
    getQuantityFixReq: 'getQuantityFixReq',
    workPaused: 'workPaused',
    shiftStarted: 'shiftStarted',
    getShiftStartedReq: 'getShiftStartedReq',
    shiftEnded: 'shiftEnded',
    getShiftEndedReq: 'getShiftEndedReq',
    newWorkAdded: 'newWorkAdded',
    setupStarted: 'setupStarted',
    workerAddRemove: 'workerAddRemove',
    workQualityRules: 'workQualityRules',
    qualityRule: 'qualityRule',
    sendQualityCheckList: 'sendQualityCheckList',
    wMSInventoryForBOM: 'wMSInventoryForBOM',
    sendMaterialMES: 'sendMaterialMES',
    sendMaterialFeedback: 'sendMaterialFeedback',
    sendMaterialTransfer: 'sendMaterialTransfer',
    sendBOMChange: 'sendBOMChange',
};

export default apiServicePut;
