function AlertCodes(error) {
    var errorParameters = {};
    var lowerCaseErrorObj = {};

    if (error.response !== undefined && error.response.data !== undefined) {
        //Change error object keys to lowercase because they come in different cases from backend -->
        var keys = Object.keys(error.response.data);
        var n = keys.length;
        while (n--) {
            var key = keys[n];
            lowerCaseErrorObj[key.toLowerCase()] = error.response.data[key];
        }
        //Change error object keys to lowercase because they come in different cases from backend <--

        if (lowerCaseErrorObj.codestr !== undefined && lowerCaseErrorObj.message !== undefined) {
            var text = '';
            var parameters = '';
            switch (lowerCaseErrorObj.codestr) {
                default:
                    text = 'errorMessages.errorCodeNotDefined';
                    parameters = { errorMessage: lowerCaseErrorObj.message };
                    break;
                case 'MES101':
                case 'MES104':
                case 'MES107':
                case 'MES109':
                    text = 'errorMessages.referenceFieldsMandatory';
                    break;
                case 'MES102':
                    text = 'errorMessages.prodOrderFieldMustBeEmpty';
                    break;
                case 'MES103':
                case 'MES110':
                    text = 'errorMessages.quantityMustBeZero';
                    break;
                case 'MES105':
                    text = 'errorMessages.quantityCantBeZero';
                    break;
                case 'MES106':
                case 'MES108':
                    text = 'errorMessages.scrapReasonMissing';
                    break;
                case 'MES112':
                    text = 'errorMessages.idFieldsMustNotBeEmpty';
                    break;
                case 'MES117':
                    text = 'errorMessages.operationDurationCantBeNegative';
                    break;
                case 'MES118':
                    text = 'errorMessages.employeeHasUnfinishedWork';
                    parameters = { wrkCtr: lowerCaseErrorObj.parameters.WrkCtr };
                    break;
                case 'MES119':
                    text = 'errorMessages.maxParallelJobsReached';
                    parameters = {
                        maxParallelTasks: lowerCaseErrorObj.parameters.MaxParallelTasks,
                    };
                    break;
                case 'MES120':
                    text = 'errorMessages.jobNotAssignedToThisWorkcenter';
                    break;
                case 'MES121':
                case 'MES140':
                    text = 'errorMessages.workcenterMaxParallelTasksReached';
                    parameters = {
                        maxParallelTasks: lowerCaseErrorObj.parameters.MaxParallelTasks,
                    };
                    break;
                case 'MES123':
                    text = 'errorMessages.transactionTypeUndefined';
                    break;
                case 'MES124':
                    text = 'errorMessages.notifyReasonMissing';
                    break;
                case 'MES125':
                    text = 'errorMessages.notifyReasonIsNotNotifyType';
                    break;
                case 'MES126':
                case 'MES127':
                    text = 'errorMessages.cantStartSetup';
                    break;
                case 'MES128':
                    text = 'errorMessages.cantStartWork';
                    break;
                case 'MES129':
                case 'MES130':
                case 'MES131':
                    text = 'errorMessages.cantEndWork';
                    break;
                case 'MES132':
                    text = 'errorMessages.workcenterFieldCantBeEmpty';
                    break;
                case 'MES133':
                    text = 'errorMessages.workcenterDataMissing';
                    parameters = { workPlaceId: lowerCaseErrorObj.parameters.WorkPlaceId };
                    break;
                case 'MES134':
                    text = 'errorMessages.prodOrderMissing';
                    break;
                case 'MES135':
                    text = 'errorMessages.prodLineOperationMissing';
                    break;
                case 'MES136':
                    text = 'errorMessages.productionLineHasDifferentRole';
                    parameters = { roleId: lowerCaseErrorObj.parameters.RoleId };
                    break;
                case 'MES137':
                    text = 'errorMessages.productionLineHasDifferentWorkplace';
                    parameters = {
                        routingWorkPlaceId: lowerCaseErrorObj.parameters.RoutingWorkPlaceId,
                        transWorkPlaceId: lowerCaseErrorObj.parameters.TransWorkPlaceId,
                    };
                    break;
                case 'MES138':
                    text = 'errorMessages.transactionTimestampDiffersFromGMT';
                    parameters = {
                        transDiffMinutes: lowerCaseErrorObj.parameters.TransDiffMinutes,
                        allowedDiffMinutes: lowerCaseErrorObj.parameters.AllowedDiffMinutes,
                    };
                    break;
                case 'MES139':
                    text = 'errorMessages.operationDurationOverMax';
                    parameters = { maxActionTime: lowerCaseErrorObj.parameters.MaxActionTime };
                    break;
                case 'MES141':
                    text = 'errorMessages.maxParallelEmployeeJobsReached';
                    parameters = {
                        maxParallelTasks: lowerCaseErrorObj.parameters.MaxParallelTasks,
                    };
                    break;
                case 'MES142':
                    text = 'errorMessages.processNotInProcessingStatus';
                    parameters = { prevStatus: lowerCaseErrorObj.parameters.PrevStatus };
                    break;
                case 'MES143':
                    text = 'errorMessages.processNotInSetupStatus';
                    parameters = { prevStatus: lowerCaseErrorObj.parameters.PrevStatus };
                    break;
                case 'MES144':
                    text = 'errorMessages.processHasNoDuration';
                    break;
                case 'MES145':
                    text = 'errorMessages.stopReasonNotesMissing';
                    break;
                case 'MES146':
                    text = 'errorMessages.processHasDuration';
                    break;
                case 'MES147':
                case 'MES149':
                    text = 'errorMessages.processNotInIdleOrProcessing';
                    parameters = { prevStatus: lowerCaseErrorObj.parameters.PrevStatus };
                    break;
                case 'MES148':
                    text = 'errorMessages.processNotInPreSetupStatus';
                    parameters = { prevStatus: lowerCaseErrorObj.parameters.PrevStatus };
                    break;
                case 'MES151':
                    text = 'errorMessages.startTheStoppedProcessFirst';
                    break;
                case 'MES152':
                    text = 'errorMessages.transactionTimestampUndefined';
                    break;
                case 'MES153':
                    text = 'errorMessages.duplicateKeyDifferentValues';
                    break;
                case 'MES155':
                    text = 'errorMessages.workNotStarted';
                    break;
                case 'MES156':
                case 'MES157':
                    text = 'errorMessages.workNotStartedWithPrevious';
                    parameters = { prevStatus: lowerCaseErrorObj.parameters.PrevStatus };
                    break;
                case 'MES201':
                case 'MES202':
                    text = 'errorMessages.noEmployeeSharing';
                    break;
                case 'MES203':
                    text = 'errorMessages.employeeNotClockedIn';
                    break;
                case 'MES204':
                    text = 'errorMessages.employeeHasActiveJobs';
                    break;
                case 'MES205':
                    text = 'errorMessages.errorWhileDeleting';
                    break;
                case 'MES206':
                    text = 'errorMessages.employeeNotClockedOut';
                    break;
                case 'MES221':
                    text = 'errorMessages.workcenterNoTeamActions';
                    break;
                case 'MES222':
                case 'MES223':
                    text = 'errorMessages.hasNotJoinedTeam';
                    break;
                case 'MES224':
                    text = 'errorMessages.alreadyJoinedTeam';
                    break;
                case 'MES225':
                    text = 'errorMessages.workcenterMaxEmployeesReached';
                    break;
                case 'WMS203':
                    text = 'errorMessages.locationNotFound';
                    break;
                case 'WMS209':
                    text = 'errorMessages.noInventoryQty';
                    parameters = {
                        itemCode: lowerCaseErrorObj.parameters.itemCode,
                        labelCode: lowerCaseErrorObj.parameters.labelCode,
                        variantCode: lowerCaseErrorObj.parameters.variantCode,
                    };
                    break;
            }
            errorParameters = { text: text, parameters: parameters };
            return errorParameters;
        }
    }
    errorParameters = { text: 'errorMessages.noErrorResponseFromServer', parameters: parameters };
    return errorParameters;
}

export default AlertCodes;
