import React from 'react';
import './login.css';
import loginpic from '../Login/img/loginpic.jpg';
import columbuslogowhite from '../Login/img/columbuslogowhite.png';
import { Link } from 'react-router-dom';

export default function FrontPage() {
    return (
        <div className='logInBackground'>
            <nav className='navbar bck-common-topbar'></nav>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='text-center login-clean'>
                        <div className='textPart'>
                            <div className='logInContainerHeading'>ColMes</div>
                            <Link to='/app'>
                                <button className='col col-offset-3 col-2 btn btn-light'>
                                    Logi sisse
                                </button>
                            </Link>
                        </div>
                        <img className='loginpic' src={loginpic} width='300px' alt='' />
                    </div>
                </div>
            </div>
            <footer className='footerBottom'>
                <div className='container-fluid'>
                    <div className='text-center'>
                        <img src={columbuslogowhite} width='190px' alt='' />
                    </div>
                    <div className='text-center'>
                        <span className='text-center footerColQuote'>
                            <span>Digitaalne muutus, kasv ja tulevikukindlus Sinu</span>
                            <span>
                                <br></br>ettevõttes
                            </span>
                        </span>
                    </div>
                    <div className='copyright text-left my-auto'>
                        <span className='copyright'>© 2020 Columbus Eesti AS</span>
                    </div>
                </div>
            </footer>
        </div>
    );
}
