import React from 'react';
import useLocale from '../../components/Helpers/locale';
import { useAlert } from 'react-alert';

/**
 *
 * @param {setModalOpened, setSelected, toggleActiveView} props
 */
const NonContFooter = props => {
    const { t } = useLocale();
    const customAlert = useAlert();

    const showNotImplMessage = () => {
        customAlert.show(t('errorCodeNotDefined', 'Not implemented yet.'));
    };

    // Main View, nothing selected
    // TODO: add redirect to ChangeList on btnNextM
    const mainDefaultFooter = (
        <>
            <button
                id='btnNextM'
                type='button'
                disabled={props.loading || false}
                className={'col-8 mr-1 bg-primary' + nonContFooter}
                onClick={() => props.toggleActiveView(2, false, false)}>
                {t('button.continue')}
            </button>
            <button
                id='historyM'
                type='button'
                disabled={props.loading || false}
                className={'col bg-dark' + nonContFooter}
                onClick={() => props.setModalOpened(true)}>
                LOG
            </button>
        </>
    );
    // Main View, at least 1 item selected
    const mainActiveFooter = (
        <>
            <button
                id='btnCancelM'
                type='button'
                className={'col bg-danger' + nonContFooter}
                onClick={() => props.toggleActiveView(0, true, true)}>
                {t('button.cancel')}
            </button>
            <button
                id='brnEnterM'
                type='button'
                className={'col ml-1 bg-success' + nonContFooter}
                onClick={() => props.toggleActiveView(1)}>
                {t('button.enter')}
            </button>
        </>
    );
    // Update View
    const updateWorkItemFooter = (
        <>
            <button
                id='btnCancelM'
                type='button'
                className={'col mr-1 bg-danger' + nonContFooter}
                onClick={() => props.toggleActiveView(0, true)}>
                {t('button.cancel')}
            </button>
            <button
                id='btnSubmitM'
                type='button'
                className={'col ml-1 bg-success' + nonContFooter}
                onClick={e => props.submit(e)}>
                {t('button.save')}
            </button>
        </>
    );
    // ChangeList
    const changeListFooter = (
        <>
            <button
                id='btnBacktM'
                type='button'
                disabled={props.loading || false}
                className={'col bg-dark' + nonContFooter}
                onClick={() => props.toggleActiveView(0)}>
                HOME
            </button>
            <button
                id='btnCancelM'
                type='button'
                disabled={props.loading || false}
                className={'col mx-1 bg-danger' + nonContFooter}
                onClick={() => props.toggleActiveView(0, true, true)}>
                {t('button.cancel')}
            </button>
            <button
                id='btnSubmitM'
                type='button'
                disabled={props.loading || false}
                className={'col bg-primary' + nonContFooter}
                onClick={e => props.submit(e)}>
                {t('button.continue')}
            </button>
        </>
    );

    const updateWorkItemModalFooter = (
        <>
            <button
                id='btnSubmitM'
                type='button'
                className={'col bg-success' + nonContFooter}
                onClick={e => props.submit(e)}
                disabled={props.loading || false}>
                {t('button.continue')}
            </button>
        </>
    );

    const viewID = props.viewID;
    const selectionsMade = props.selectionsMade;

    let footerRes = <></>;
    switch (viewID) {
        case 0: // Main View
            if (selectionsMade) {
                footerRes = mainActiveFooter;
            } else {
                footerRes = mainDefaultFooter;
            }
            break;
        case 1: // UpdateWorkItem
            footerRes = updateWorkItemFooter;
            break;
        case 2: // ChangeList
            footerRes = changeListFooter;
            break;
        case 3:
            footerRes = updateWorkItemModalFooter;
            break;
        default:
            showNotImplMessage();
    }

    return (
        <div className='card-footer py-2 row justify-content-between px-1 py-1' id='nonContFooter-footer'>
            {footerRes}
        </div>
    );
};

const nonContFooter = ' btn btn-secondary text-white px-1 py-1';

export default NonContFooter;
