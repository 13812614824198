import axios from 'axios';
import { HEADERS, APIURI } from '../../config';

const makeURI = (uri, company, type = 'mes') => {
    let localUri = (uri.startsWith('http') ? '' : `${APIURI}/api2/${company}/${type}/`) + uri;
    return localUri;
};

const makeRequest = (uri, company, headers) => {
    const localUri = makeURI(uri, company);
    const result = axios.get(localUri, headers);
    return result;
};

export const loadUserData = () => {
    return makeRequest(APIURI + '/api/auth/me');
};

export const loadPinUserData = ({ pin, defaultData }) => {
    HEADERS['X-ColAppAuth-Pin'] = `PIN ${pin}`;
    return makeRequest(APIURI + '/api/auth/me/' + defaultData.companyId, undefined, {
        headers: HEADERS,
    });
};

export const loadIdUserData = ({ userId, defaultData }) => {
    HEADERS['X-ColAppAuth-Pin'] = `USER ${userId}`;
    return makeRequest(APIURI + '/api/auth/me/' + defaultData.companyId, undefined, {
        headers: HEADERS,
    });
};

export const loadRolesData = ({ company }) => {
    return makeRequest(`${APIURI}/api2/${company}/auth/role`);
};

export const loadCompanyData = ({ company }) => {
    return makeRequest(`${APIURI}/api/company/${company}`);
};

export const loadCompanyParameters = ({ company }) => {
    return makeRequest(makeURI('companyparam', company));
};

const companyEmployees = ({ company }) => {
    return makeRequest(`${APIURI}/api/auth/entity/employee/${company}`);
};

const newWorkItemsURI = ({
    searchTerm = null,
    prodOrderId = null,
    fetchAll = false,
    defaultData,
}) => {
    let uri = `prodstatus?query=flatlist&role=${defaultData.defaultRole}&workplace=${defaultData.workCenterId}`;
    if (prodOrderId) {
        uri += `&prodorderid=${prodOrderId}`;
    }
    if (searchTerm) {
        uri += `&search=${searchTerm}`;
    }
    if (fetchAll) {
        uri += '&show=all';
    }
    return makeURI(uri, defaultData.companyId);
};

const getLastWorkedHoursURI = ({ timezoneHr = 2, days = 31, defaultData }) => {
    return makeURI(
        `trans?query=totals&workplace=${defaultData.workCenterId}&reportedemplid=${defaultData.userId}&timezonehr=${timezoneHr}&days=${days}`,
        defaultData.companyId
    );
};

const allActiveWorkItemsURI = ({ defaultData }) => {
    return makeURI(
        `prodstatus?query=emplstatus&role=${defaultData.defaultRole}&workplace=${defaultData.workCenterId}`,
        defaultData.companyId
    );
};

const teamworkActiveWorkItemsURI = ({ defaultData }) => {
    return makeURI(
        `wrkctrstatus?query=prodstatus&current=yes&workplace=${defaultData.workCenterId}`,
        defaultData.companyId
    );
};

const getTransactionLogURI = ({ count, workplace, hours = 1000, defaultData }) => {
    let uri = makeURI(
        `trans?query=history&count=${count}&hours=${hours}&reportedemplid=${defaultData.userId}`,
        defaultData.companyId
    );
    if (workplace !== undefined) uri += `&workplace=${workplace}`;

    return uri;
};
const getTransactionLogMesURI = ({ itemId, defaultData }) => {
    let uri = makeURI(`trans?query=history&prodTransId=${itemId}`, defaultData.companyId, 'wms');
    return uri;
};

//TODO remove it from UpdateWorkItemModal
export const getTransactionDetailsMes = uri => {
    return makeRequest(uri);
};

const workCenters = ({ company }) => {
    return makeRequest('wrkctr', company);
};

const stopReasons = ({ company }) => {
    return makeRequest('prodstopreason', company);
};

const getWorkTime = ({ defaultData }) => {
    return makeRequest('emplstatus?query=emplstatus&current=yes', defaultData.companyId);
};
const getExtUserStatus = ({ defaultData }) => {
    return makeRequest('emplstatus?query=availableops&current=yes', defaultData.companyId);
};

const getWorkcenterInfo = ({ workcenter, defaultData }) => {
    return makeRequest(
        `wrkctrstatus?query=wrkctrstatus&current=yes&workplace=${workcenter}`,
        defaultData.companyId
    );
};

const getWorkcenterPlannedTime = ({ userRole, workcenter, defaultData }) => {
    return makeRequest(
        `prodstatus?query=tasklisttotals&role=${userRole}&workplace=${workcenter}`,
        defaultData.companyId
    );
};

const getBomList = ({ selectedJob, defaultData }) => {
    return makeRequest(
        `prodstatus?query=bom&prodorderid=${selectedJob.item.prodOrderId}&oprNo=${selectedJob.item.oprNo}`,
        defaultData.companyId
    );
};

const getLabelInfo = ({ inputLabel, defaultData }) => {
    let uri = makeURI(
        `inventlabel/${inputLabel}?type=virtualextended`,
        defaultData.companyId,
        'wms'
    );
    return makeRequest(uri);
};
const getMatFeedbackFormSetup = ({ company, defaultData }) => {
    return makeRequest(
        `${APIURI}/api2/${company || defaultData.companyId}/storage/blob/prodmatformsetup`
    );
};

const coProductsQtyFormSetupUri = ({ company, defaultData }) => {
    return `${APIURI}/api2/${company || defaultData.companyId}/storage/blob/coproductvariantsetup`;
};

const getCoProductsURI = ({ prodOrderId, oprNo, defaultData }) => {
    let uri = makeURI(`prodstatus?query=coproducts&prodorderid=${prodOrderId}&oprno=${oprNo}`, defaultData.companyId);
    return uri;
};

const ApiServiceGet = {
    loadUserData,
    loadPinUserData,
    loadRolesData,
    loadCompanyData,
    workCenters,
    stopReasons,
    getWorkTime,
    getWorkcenterInfo,
    getWorkcenterPlannedTime,
    getBomList,
    companyEmployees,
    newWorkItemsURI,
    getLastWorkedHoursURI,
    allActiveWorkItemsURI,
    teamworkActiveWorkItemsURI,
    getTransactionLogURI,
    getTransactionLogMesURI,
    getLabelInfo,
    getMatFeedbackFormSetup,
    getExtUserStatus,
    loadCompanyParameters,
    coProductsQtyFormSetupUri,
    getCoProductsURI,
};

export default ApiServiceGet;
