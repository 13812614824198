import React, { useContext } from 'react';
import { useAlert } from 'react-alert';
import useAxios from 'axios-hooks';
import AlertCodes from '../../components/Helpers/AlertCodes';
import useLocale from '../../components/Helpers/locale';
import WorkItemsView from '../WorkItemsView/WorkItemsView';
import TimeTracking from '../TimeTracking/TimeTracking';
import NonContFooter from '../NonContFooter/NonContFooter';
import LoadAnim from '../../components/LoadAnim/LoadAnim';
import { NONCONT_VIEW_ID } from '../Core/Core';
import { DashboardCtx } from '../../components/AppContext/DashboardStore';
import { GlobalCtx } from '../../components/AppContext/GlobalStore';
import apiServicePut, { PutActions } from '../../components/ApiService/ApiServicePut';

import { getWorkEndedReq, getNonContWorkUpdReq } from '../../components/ApiService/ApiServicePut';

const SubmitWorkItem = props => {
    const items = props.data;
    const { t } = useLocale();
    const customAlert = useAlert();
    const { dashActions } = useContext(DashboardCtx);
    const { state } = useContext(GlobalCtx);
    // const [loading, setLoading] = useState(false);

    // PUT api requests
    // const sendWorkEndObj = getNonContWorkUpdReq();
    const [{ loading }, requestWorkUpd] = useAxios(
        {
            url: apiServicePut({ state, action: PutActions.getNonContWorkUpdReq }).uri,
            method: 'PUT',
        },
        { manual: true }
    );

    const createRequests = async workStarted => {
        // should return nothing if finish request is unnecessary
        let finishRequest = null;

        if (workStarted.additionalFinished) {
            finishRequest = await apiServicePut({
                state,
                action: PutActions.getWorkEndedReq,
                props: {
                    workStarted,
                    data: {
                        done: true,
                    },
                },
            });
        }
        return {
            updWorkReq: await apiServicePut({
                state,
                action: PutActions.getNonContWorkUpdReq,
                props: { workStarted, timeElapsed: workStarted.additionalTime },
            }),
            finishRequest,
        };
    };

    const submit = async function(e) {
        e.preventDefault();
        for (const item of items) {
            const { updWorkReq, finishRequest } = await createRequests(item);
            console.dir(updWorkReq);
            console.dir(finishRequest);
            // Update task, updWorkReq.reqBody.type 18 or 19 supported
            if (
                item.additionalTime !== 0 &&
                item.allowedOps.indexOf(updWorkReq?.reqBody.type) !== -1
            ) {
                await requestWorkUpd({ data: updWorkReq.reqBody }).then(
                    () => {
                        console.log('successful individual data update');
                    },
                    error => {
                        customAlert.show(t(AlertCodes(error).text, AlertCodes(error).parameters));
                    }
                );
            }
            // If task is to be marked as "finished"
            if (item.allowedOps.indexOf(finishRequest?.reqBody.type) !== -1) {
                await requestWorkUpd({ data: finishRequest.reqBody }).then(
                    () => {
                        console.log('successful workItem ended');
                    },
                    error => {
                        customAlert.show(t(AlertCodes(error).text, AlertCodes(error).parameters));
                    }
                );
            }
        }
        props.toggleActiveView(0, true, true);
        dashActions.reloadListData();
    };
    return (
        <>
            <div className='card-body leftAndRightNavHeading m-0 p-0 d-flex flex-column'>
                <div className='card-title'>
                    <TimeTracking
                        state={props.globalState}
                        stateReload={props.dashState.reloadData}
                    />
                </div>

                <h5 className='row leftAndRightNavHeading pt-2'>{t('header.selectJob')}</h5>

                {loading ? (
                    <LoadAnim animtext='Salvestatakse andmeid' />
                ) : (
                    <div className='card-text row d-flex overflow-auto justify-content-start mx-1'>
                        <WorkItemsView
                            data={items}
                            setSelected={props.setSelected}
                            itemsSelected={items}
                            editable={false}
                            emptyMsg={'emptyUpdateList'}
                        />
                    </div>
                )}
            </div>
            <NonContFooter
                viewID={NONCONT_VIEW_ID.SUBMIT}
                submit={submit}
                toggleActiveView={props.toggleActiveView}
                loading={loading}
            />
        </>
    );
};

export default SubmitWorkItem;
