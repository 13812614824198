import React from 'react';
import './loadAnim.css';

export default function LoadAnim(props) {
    const animtext = props.animtext;

    return (
        <div className='loader-container w-100'>
            <h1>{animtext}</h1>
            <div className='loader'>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}
