import React, { useEffect, useState } from 'react';
import TimeTracking from '../TimeTracking/TimeTracking';
import Log from '../Log/Log';
import WorkItemsView from '../WorkItemsView/WorkItemsView';
import UpdateWorkItem from '../UpdateWorkItem/UpdateWorkItem';
import useModal from '../../components/Modal/Modal';
import useLocale from '../../components/Helpers/locale';
import NonContFooter from '../NonContFooter/NonContFooter';
import SubmitWorkItem from '../SubmitWorkItem/SubmitWorkItem';
import LoadAnim from '../../components/LoadAnim/LoadAnim';
import { useAlert } from 'react-alert';

export const NONCONT_VIEW_ID = {
    MAIN: 0,
    UPDATE: 1,
    SUBMIT: 2,
    UPDATE_MODAL: 3,
};

const Core = props => {
    const [Modal, setModalOpened] = useModal({
        opened: false,
        focusTrapped: true,
    });
    const [itemsSelected, setSelected] = useState([]);
    const [activeView, setActiveView] = useState(0);
    const { t } = useLocale();
    const customAlert = useAlert();

    let [data, setData] = useState(props.data);

    const getItemsForUpdate = () => {
        if (data === undefined) return [];
        return data.filter(
            item => item.additionalTime !== undefined || item.additionalFinished !== undefined
        );
    };

    const toggleActiveView = (newValue = 0, deselect = false, cancel = false) => {
        if (deselect) setSelected([]);
        if (cancel) {
            let newData = [...data];
            let index;
            if (itemsSelected.length > 0) {
                itemsSelected.forEach(item => {
                    index = newData.indexOf(item);
                    delete item.additionalFinished;
                    delete item.additionalTime;
                    newData.splice(index, 1, item);
                });
            } else {
                getItemsForUpdate().forEach(item => {
                    index = newData.indexOf(item);
                    delete item.additionalFinished;
                    delete item.additionalTime;
                    newData.splice(index, 1, item);
                });
            }
            setData(newData);
        }
        setModalOpened(false);
        setActiveView(newValue);
    };
    const showNotImplMessage = () => {
        customAlert.show(t('errorCodeNotDefined', 'Not implemented yet.'));
    };

    useEffect(() => {
        if (props.data === data) return;
        if (props.data === undefined) setData([]);
        else setData(props.data);
    }, [props.data]);

    useEffect(() => {
        setSelected([]);
        setActiveView(0);
        setModalOpened(false);
    }, [props.dashState.reloadData]);

    if (activeView === 1) {
        return (
            <UpdateWorkItem
                data={data}
                setSelected={setSelected}
                itemsSelected={itemsSelected}
                toggleActiveView={toggleActiveView}
                showNotImplMessage={showNotImplMessage}
                setForUpdate={setData}
            />
        );
    } else if (activeView === 2) {
        return (
            <SubmitWorkItem
                data={getItemsForUpdate()}
                toggleActiveView={toggleActiveView}
                setSelected={setSelected}
                globalState={props.globalState}
                dashState={props.dashState}
            />
        );
    }

    /* console.group();
    console.dir(data);
    console.log('->');
    console.dir(getItemsForUpdate());
    console.groupEnd(); */
    return (
        <>
            <div className='card-body leftAndRightNavHeading m-0 p-0 d-flex flex-column'>
                <div className='card-title'>
                    <TimeTracking
                        state={props.globalState}
                        stateReload={props.dashState.reloadData}
                    />
                </div>

                <h5 className='row leftAndRightNavHeading m-0 px-3 pt-2'>{t('header.selectJob')}</h5>

                <div className='card-text row d-flex overflow-auto justify-content-start mx-1'>
                    {props.isLoading ? (
                        <LoadAnim animtext='Laaditakse andmeid' />
                    ) : (
                        <WorkItemsView
                            data={data}
                            setSelected={setSelected}
                            itemsSelected={itemsSelected}
                            editable={true}
                        />
                    )}
                </div>
            </div>

            <NonContFooter
                viewID={NONCONT_VIEW_ID.MAIN}
                selectionsMade={itemsSelected?.length > 0}
                setModalOpened={setModalOpened}
                toggleActiveView={toggleActiveView}
                loading={props.isLoading}
            />
            <div id='modalWindowDiv'>
                <Modal>
                    <Log
                        globalState={props.globalState}
                        dashState={props.dashState}
                        toggleActiveView={toggleActiveView}
                    />
                </Modal>
            </div>
        </>
    );
};

export default Core;
