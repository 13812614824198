import { transitions, positions } from 'react-alert';
export const HEADERS = {};
export const authData = {
    apiuri: process.env.REACT_APP_API_URI,
    headers: HEADERS,
};
export const APIURI = process.env.REACT_APP_API_URI;
export const alertOptions = {
    position: positions.TOP,
    transition: transitions.FADE,
    containerStyle: {
        zIndex: 2000,
        paddingTop: 10,
        paddingLeft: 10000,
        paddingRight: 10000,
        paddingBottom: 10000,
        boxShadow: 'inset 0 0 0 1000rem rgba(160,160,160,0.5)',
        MozBoxShadow: 'inset 0 0 0 1000rem rgba(160,160,160,0.5)',
        WebkitBoxShadow: 'inset 0 0 0 1000rem rgba(160,160,160,0.5)',
    },
};
