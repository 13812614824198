import React, { useState, useEffect, useRef, useContext } from 'react';

// import { getRowHeight } from './WorkItemListNewLogic';
import { DashboardCtx } from '../../AppContext/DashboardStore';
import { GlobalCtx } from '../../AppContext/GlobalStore';
import ListItem from '../../List/ListItem';
import useLocale from '../../Helpers/locale';
import { WorkplaceFlags } from '../../Helpers/enums';
import { splitDateTime } from '../../Helpers/timeUtils';
import { formatTime } from '../../Helpers/timeUtils';
import DataLoading from '../../LoadAnim/DataLoading';

const SearchBar = props => {
    const setLocalItems = props.listDataSet;
    const items = props.items;
    const localItems = props.localItems;
    const remoteSearch = props.remoteSearch || false;
    const onSearch = props.onSearch || null;

    const { state } = useContext(GlobalCtx);
    const searchItemRef = useRef(null);
    const [input, setInput] = useState('');
    const { t } = useLocale();

    useEffect(() => {
        setTimeout(() => {
            if (searchItemRef.current) searchItemRef.current.focus();
        }, 1);
    }, [searchItemRef]);

    const search = e => {
        if (props.remoteSearch) return;
        const searchStr = e.currentTarget.value.toLowerCase();
        if (searchStr.length < 5) {
            if (localItems.length !== items.length) {
                setLocalItems(
                    state.currentWrkCtr.flags.includes(WorkplaceFlags.ListDefaultHidden)
                        ? []
                        : items
                );
            }
            return;
        }

        let searchResult = items.filter(item => {
            let ret = searchStrMatchItem(item.prodOrderId, searchStr);
            ret = ret || searchStrMatchItem(item.itemId, searchStr);
            if (item.attrib?.lineDescription !== undefined)
                ret = ret || searchStrMatchItem(item.lineDescription, searchStr);
            if (item.attrib?.Customer !== undefined)
                ret = ret || searchStrMatchItem(item.attrib.Customer, searchStr);
            if (item.attrib?.salesOrder !== undefined)
                ret = ret || searchStrMatchItem(item.attrib.salesOrder, searchStr);
            return ret;
        });

        setLocalItems(searchResult);
    };
    const apiSearch = () => {
        onSearch(searchItemRef.current.value);
    };

    const searchStrMatchItem = (value, searchStr) => {
        if (value == null) return false;
        return value.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1;
    };

    return (
        <div id='searchBox' className='input-group input-group-lg mr-3'>
            <input
                value={input}
                onChange={e => search(e)}
                onInput={e => setInput(e.target.value)}
                className='form-control'
                type='text'
                placeholder={t('search_c', { text: t('search') })}
                ref={searchItemRef}
                onClick={e => e.currentTarget.select()}
                disabled={!props.active}
                onKeyPress={e => {
                    if (e.key === 'Enter') apiSearch();
                }}
            />
            {remoteSearch && onSearch !== null && (
                <div className='input-group-append'>
                    <button
                        className='btn btn-primary'
                        type='button'
                        id='search-button'
                        onClick={e => apiSearch(e)}>
                        Otsi
                    </button>
                </div>
            )}
        </div>
    );
};

const ItemList = props => {
    const showInModal = props.modal;
    const callerOnClick = props.onClick || null;
    const remoteSearch = props.remoteSearch || false;
    const remoteSearchMade = props.remoteSearchTerm || false;

    const [localItems, setLocalItems] = useState(props.items);
    const [showSearch, setShowSearch] = useState(true);
    const { dashState, dashActions } = useContext(DashboardCtx);
    const { state } = useContext(GlobalCtx);

    const wrapperClass = () => {
        let cls = 'row plannedRow';
        if (showInModal) cls += ' modalPanel';
        return cls;
    };

    useEffect(() => {
        if (props.items !== localItems) setLocalItems(props.items);
        let showSearchLocal = true;
        if (!remoteSearch && props.items.length === 0) showSearchLocal = false;
        setShowSearch(showSearchLocal);
    }, [props.items, remoteSearch]);

    return (
        <div className={wrapperClass()}>
            <div className='col ml-3 mr-3 mh-100 d-flex flex-column'>
                {showSearch && (
                    <SearchBar
                        listDataSet={setLocalItems}
                        localItems={localItems}
                        items={props.items}
                        active={true}
                        remoteSearch={remoteSearch}
                        onSearch={props.onSearch}
                    />
                )}
                <LocalListBody
                    error={props.error || null}
                    showInModal={showInModal}
                    remoteSearch={remoteSearch}
                    localItems={localItems}
                    dashState={dashState}
                    state={state}
                    dashActions={dashActions}
                    callerOnClick={callerOnClick}
                    remoteSearchMade={remoteSearchMade}
                    dataLoaded={props.dataLoaded}
                />
            </div>
        </div>
    );
};

const LocalListBody = props => {
    const { t } = useLocale();
    const remoteSearch = props.remoteSearch;
    const [items, setItems] = useState([]);
    // const listRef = useRef({});
    const dashState = props.dashState;
    const state = props.state;
    const dashActions = props.dashActions;
    const callerOnClick = props.callerOnClick;

    useEffect(() => {
        if (props.localItems === items) return;
        if (props.localItems === undefined) setItems([]);
        setItems(props.localItems);
    }, [props.localItems]);

    const listClass = () => {
        let baseClass = 'leftAndRightList';
        baseClass += props.showInModal ? ' localModal' : ' localPanel';
        return baseClass;
    };
    const EmptyList = ({ msg }) => {
        return (
            <div className={listClass()}>
                <h4>{msg}</h4>
            </div>
        );
    };

    if (props.error)
        return (
            <div className={listClass()}>
                <h4>Viga andmete laadimisel!</h4>
            </div>
        );
    if (!remoteSearch) {
        if (props.dataLoaded === true && items.length === 0)
            return <EmptyList msg={t('workItemList.emptyList')} />;
    }
    if (remoteSearch && props.remoteSearchMade) {
        if (props.dataLoaded === true && items.length === 0)
            return <EmptyList msg={t('workItemList.searchEmpty')} />;
    }
    if (props.dataLoaded === false) return <DataLoading />;

    const ItemRow = props => {
        const row = props.row;
        const item = row.item;
        const plannedDt = splitDateTime({
            dateTime: item.oprSchedDate !== undefined ? item.oprSchedDate : '0001-01-01T00:00:00',
        });
        const [activeItem, setActiveItem] = useState('');
        const { t } = useLocale();

        useEffect(() => {
            if (
                item.allReportedProcessTime + item.allReportedSetupTime > 0 &&
                dashState.selectedJob.type !== 'new'
            ) {
                setActiveItem('paused');
            } else if (
                item.allReportedProcessTime + item.allReportedSetupTime > 0 &&
                dashState.selectedJob.type === 'new'
            ) {
                if (dashState.selectedJob.key === row.key) {
                    setActiveItem('pausedActive');
                } else if (dashState.selectedJob.key !== row.key) {
                    setActiveItem('paused');
                }
            } else if (item.oprStatus === 0 && dashState.selectedJob.type === 'new') {
                if (dashState.selectedJob.key === row.key) {
                    setActiveItem('active');
                } else if (dashState.selectedJob.key !== row.key) {
                    setActiveItem('');
                }
            } else if (dashState.selectedJob.type === undefined) {
                setActiveItem('');
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dashState.selectedJob]);

        const normTime = () => {
            return formatTime({ time: item.oprSchedMinutes, unit: 'minutes' });
        };

        const setFolderPath = () => {
            let retObj = { show: false, uri: '', showCoProducts: false, coProductUris: null };
            if (
                !state.currentWrkCtr.flags?.includes(WorkplaceFlags.ShowFolder) ||
                state.currentWrkCtr.attrib?.documentFolderPath === null ||
                state.currentWrkCtr.attrib?.documentFolderPath === ''
            )
                return retObj;

            if (!item.hasCoProducts && item.attrib?.folderPath == null) return retObj;

            let folderPath = state.currentWrkCtr.attrib.documentFolderPath;
            if (folderPath === '') return retObj;

            if (!folderPath.endsWith('/')) {
                if (!folderPath.startsWith('/')) folderPath += '/';
            }
            retObj.show = true;
            retObj.uri = folderPath;
            if (item.attrib?.folderPath != null) retObj.uri += item.attrib.folderPath;

            if (item.hasCoProducts) {
                retObj.showCoProducts = true;
                retObj.coProductUris = {};
                Object.entries(item.coProducts).forEach(entry => {
                    const [key, coProduct] = entry;
                    retObj.coProductUris[key] = folderPath + coProduct.attrib.folderPath;
                });
            }
            return retObj;
        };

        const salesOrderName = () => {
            if (item.attrib?.salesOrder == null) return '';
            return item.attrib.salesOrder;
        };

        const itemClick = row => {
            if (
                dashState.selectedJob.type === 'active' ||
                (dashState.selectedJob.item !== undefined &&
                    dashState.selectedJob.item.key === item.key)
            ) {
                dashActions.setJobUnSelected();
            } else {
                dashActions.setJobSelected({ type: 'new', ...row });
            }
            if (callerOnClick !== null && typeof callerOnClick === 'function') {
                callerOnClick(row);
            }
        };

        const itemObj = () => {
            let listItem = { ...item };
            listItem.wrkCtrName = wrkCtrName(item.workPlaceId);
            listItem.normTime = t('workItemList.normTime', { time: normTime() });
            listItem.scrapQty = t('workItemList.scrapQty', { scrapQty: item.scrapQty });
            listItem.folderPathObj = setFolderPath();
            listItem.salesOrder = salesOrderName();
            listItem.plannedDt = t('workItemList.plannedDate', {
                date: plannedDt.date,
                time: plannedDt.time,
            });

            if (item.oprStatusUI === 2 && item.prodActiveWorkers === 0) {
                listItem.oprStatus = t('oprStatus.stopped');
            } else {
                listItem.oprStatus = t('oprStatus.status', { state: item.oprStatusUI });
            }
            return listItem;
        };

        //TODO maybe we should make a helper. Search code for similar functions
        const wrkCtrName = wrkCtrId => {
            const wrkCtr = state.wrkCtrs.find(w => w.id === wrkCtrId);
            return wrkCtr !== undefined ? wrkCtr.name : '';
        };

        return (
            <>
                <ListItem
                    props={{
                        item: itemObj(),
                        row,
                        key: row.key,
                        activeItem,
                        clickFn: itemClick,
                        currentWrkCtr: state.currentWrkCtr,
                        isAccordion: true,
                    }}
                />
            </>
        );
    };

    return (
        <div className='row overflow-auto'>
            <div className='col' id='inWorkColumn'>
                <ul className='leftAndRightList contentWidth mt-0'>
                    {items.map((item, idx) => (
                        <ItemRow row={{ item: item, key: idx }} key={idx} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ItemList;
