import React, { useState, useEffect } from 'react';
import ApiServiceGet, { ApiServiceGetActions } from '../../ApiService/ApiServiceGet_new';
import useAxios from 'axios-hooks';

import WorkItemList from './WorkItemList';
import Header from '../Header';
import { transformNewListData } from './WorkItemListNewLogic';
import useLocale from '../../Helpers/locale';
import { WorkplaceFlags } from '../../Helpers/enums';
import Core from '../../../components_nonCont/Core/Core';

export const panelCompObj = { name: 'list' };

const NewWorkItemListComp = props => {
    const [listData, setListData] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);

    const state = props.state;
    const stateReload = props.dashState.reloadData;
    const [localLastState, setLocalState] = useState(!stateReload);
    const openInModal = props.modal || false;
    const remoteSearch =
        props.remoteSearch || state.currentWrkCtr.flags?.includes(WorkplaceFlags.ListDefaultHidden);
    const [reqUri, setReqUri] = useState(
        ApiServiceGet({
            state,
            action: ApiServiceGetActions.newWorkItemsURI,
            props: { searchTerm },
        })
    );
    const { t } = useLocale();
    const [{ data, loading, error }, refetch] = useAxios(
        { url: reqUri },
        {
            manual: remoteSearch && searchTerm == null,
        }
    );

    useEffect(() => {
        setReqUri(() =>
            ApiServiceGet({
                state,
                action: ApiServiceGetActions.newWorkItemsURI,
                props: { searchTerm },
            })
        );
    }, [searchTerm, state]);

    useEffect(() => {
        if (stateReload === localLastState) return;
        if (data != null) refetch();
        setLocalState(stateReload);
    }, [stateReload]);

    useEffect(() => {
        transformNewListData({ listData: data, setData: setListData });
    }, [data]);

    if (state.currentWrkCtr.flags.indexOf('NonContinuous') !== -1) {
        return (
            <>
                <Core
                    data={listData}
                    globalState={state}
                    dashState={props.dashState}
                    isLoading={loading}
                />
            </>
        );
    }
    return (
        <>
            <div className='container-xl d-flex flex-column p-0'>
                <Header title={t('header.selectJob')} />

                <WorkItemList
                    items={listData}
                    dataLoaded={!loading || (remoteSearch && searchTerm == null)}
                    remoteSearch={remoteSearch}
                    onSearch={setSearchTerm}
                    error={error}
                    remoteSearchTerm={searchTerm !== null}
                    modal={openInModal}
                    onClick={props.onSelect || null}
                />
            </div>
        </>
    );
};
const NewWorkItemList = props => {
    return <NewWorkItemListComp {...props} />;
};
export default NewWorkItemList;
