import React from 'react';
import { Provider } from 'react-alert';
import { alertOptions } from '../../config.js';

const AlertTemplate = ({ message, close }) => (
    <div>
        <div className='alertContainer alert alert-danger alert-dismissible text-uppercase'>
            {message}
            <button className='close' onClick={close}>
                X
            </button>
        </div>
    </div>
);

const AlertProvider = props => {
    return (
        <Provider template={AlertTemplate} {...alertOptions}>
            {props.children}
        </Provider>
    );
};

export default AlertProvider;
